import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";

import React, { Fragment, useEffect, useState } from "react";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  addAnnouncement,
  loadAllAnnouncement,
} from "../../redux/rtk/features/announcement/announcementSlice";

const AddAnnouncement = ({ drawer }) => {
  const { loading } = useSelector((state) => state.announcement);
  const [announcement, setAnnouncement] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllAnnouncement());
  }, []);

  const { Title } = Typography;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const resp = await dispatch(addAnnouncement(values));

    if (resp.payload.message === "success") {
      form.resetFields();
      dispatch(loadAllAnnouncement());
      setAnnouncement(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
  };

  return (
    <>
      <h2 className="text-start text-[24px] font-[600] txt-color-2 mb-6">
        All Announcements
      </h2>

      <div className="flex justify-end mb-4 mr-4 mt-2">
        <Button
          type="submit"
          className="text-[15px] font-[400] bg-[#E42976] text-white border-0"
          onClick={() => setAnnouncement(true)}
        >
          Add Announcement
        </Button>
      </div>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Announcement
          </div>
        }
        className="global-modal-custom"
        centered
        open={announcement}
        onOk={() => setAnnouncement(false)}
        onCancel={() => setAnnouncement(false)}
        footer={null}
      >
        <Fragment>
          <Row justify={drawer ? "center" : "space-between"}>
            <Col xl={24} xs={24}>
              <Form
                form={form}
                style={{ marginBottom: "40px" }}
                eventKey="Announcement"
                layout="vertical"
                name="basic"
                labelCol={{
                  xs: { offset: 0, span: 12 },
                  sm: { offset: 0, span: 12 },
                  md: { offset: 4, span: 16 },
                  xl: { offset: 4, span: 16 },
                  lg: { offset: 4, span: 16 },
                }}
                wrapperCol={{
                  xs: { offset: 0, span: 12 },
                  sm: { offset: 0, span: 12 },
                  md: { offset: 4, span: 16 },
                  xl: { offset: 4, span: 16 },
                  lg: { offset: 4, span: 16 },
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="Meeting at 00:00"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    label="Description"
                    name={"description"}
                  >
                    <Input.TextArea
                      className="border-none ant-shadow"
                      placeholder="Description"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { span: 18, offset: 0 },
                      sm: { span: 12, offset: 6 },
                      md: { span: 16, offset: 4 }, // Remove offset for md screen
                      lg: { span: 16, offset: 4 },
                      xl: { span: 16, offset: 4 },
                    }}
                  >
                    <Button
                      type="submit"
                     className="bg-[#E42976] text-white border-0"
                      size="medium"
                      block
                      htmlType="submit"
                      loading={loading}
                    >
                      Add Announcement
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </Fragment>
      </Modal>
    </>
  );
};

export default AddAnnouncement;
