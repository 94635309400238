import { Button, Form, Input, Modal, Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateDepartment } from "../../department/departmentApis";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";

const DepartmentEditPopup = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams("id");
	const { Title } = Typography;

	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();

	const onFinish = async (values) => {
		setLoader(true);
		const resp = await updateDepartment(id, values);

		if (resp.message === "success") {
			setLoader(false);
			navigate(-1);
			setInitialValues({
				name: values.name,
			});
		} else {
			setLoader(false);
		}
	};

	const [initialValues, setInitialValues] = useState({
		name: data?.name || "",
	});

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
		setLoader(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	return (
		<>
			<button onClick={showModal}>
				<AiOutlineEdit size={30} className=" text-[#23B9F8]" />
			</button>
			<Modal
				// title='Basic Modal'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				width={600}
				height={300}

				className="bank-holiday-modal"
			>
				<Title level={4} className='m-2 mt-5 mb-5 text-center'>
					Add Department
				</Title>
				<Form
					style={{ marginBottom: "100px" }}
					eventKey='department-form'
					initialValues={initialValues}
					name='basic'
					labelCol={{
						span: 6,
					}}
					wrapperCol={{
						span: 12,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete='off'>
					<div>
						<Form.Item
							style={{ marginBottom: "20px" }}
							label='Name'
							name='name'
							rules={[
								{
									required: true,
									message: "Please input your department name!",
								},
							]}>
							<Input className="border-none ant-shadow" />
						</Form.Item>

						<Form.Item
							style={{ marginBottom: "10px" }}
							wrapperCol={{
								xs: { span: 24, offset: 0 },
								sm: { span: 24, offset: 0 },
								md: { span: 12, offset: 6 }, 
								lg: { span: 12, offset: 6 },
								xl: { span: 12, offset: 6 },
							}}
						>
							<Button
								onClick={() => setLoader(true)}
								type='submit'
								className="bg-[#E42976] text-white border-0"
								size='medium'
								htmlType='submit'
								block
								loading={loader}
							>
								update department
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	);
};
export default DepartmentEditPopup;
