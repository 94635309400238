import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteLocation, loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

const LocationDelete = ({ id }) => {
  const [locmodal, setLocModal] = useState(false)

  return (
    <>
      <button onClick={() => setLocModal(true)} className="mr-3 ml-2">
        <MdDelete size={25} color="#FF0000" />
      </button>
      <DelModal
        open={locmodal}
        onClose={setLocModal}
        locId={id}
        content={"Are you sure you want to delete?"}
        type={"loctiondel"}
      />
    </>
  );
};
export default LocationDelete;
