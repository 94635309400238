import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loadSingleStaff } from "../../../redux/rtk/features/user/userSlice";
import BtnEditSvg from "../Button/btnEditSvg";
import { updateSalaryHistory } from "../../salaryHistory/salaryHistoryApis";
import moment from "moment";

const SalaryEditSinglePopup = ({ data, setLoading }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [salaryStartDate, setsalaryStartDate] = useState(
		dayjs(data?.startDate).format()
	);
	const [salaryEndDate, setsalaryEndDate] = useState(
		dayjs(data?.endDate).format()
	);
	const [loader, setLoader] = useState(false);
	const [salaryComment, setsalaryComment] = useState(data?.comment);

	const user_id = useParams("id");
	const dispatch = useDispatch();

	const [initialValues, setInitialValues] = useState({
		salary: data?.salary,
		salaryStartDate: moment(data?.startDate),
		salaryEndDate: moment(data?.endDate),
		salaryComment: data?.comment,
	});

	const onFinish = async (values) => {
		setLoading(true);
		const id = data.id || "";
		setLoader(true);
		const infodata = {
			...values,
			salary: parseInt(values.salary),
			salaryComment: salaryComment || "",
			salaryStartDate: salaryStartDate,
			salaryEndDate: salaryEndDate,
		};

		const resp = await updateSalaryHistory(id, infodata);

		if (resp.message === "success") {
			setLoader(false);
			dispatch(loadSingleStaff(user_id.id));
			setInitialValues({});

			setsalaryComment("");
			setsalaryStartDate();
			setsalaryEndDate();

			setIsModalOpen(false);
			setLoading(false);
			window.location.reload();
		} else {
			setLoading(false);
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding designation");
		setLoader(false);
		setLoading(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setsalaryComment("");
		setsalaryStartDate();
		setsalaryEndDate();
		setIsModalOpen(false);
		setLoader(false);
		setLoading(false);
	};
	const handleCancel = () => {
		setsalaryComment("");
		setsalaryStartDate();
		setsalaryEndDate();
		setIsModalOpen(false);
		setLoader(false);
		setLoading(false);
	};


  const validateNumericInput = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter only numeric values');
  };

	return (
    <>
      <button onClick={showModal} className="mr-2">
        <BtnEditSvg size={20} />
      </button>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            {`Edit Salary ${data?.id}`}
          </div>
        }
        className="global-modal-custom"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ marginBottom: "50px" }}
          eventKey="design-form"
          initialValues={initialValues}
          name="basic"
          layout="vertical"
          labelCol={{
            span: 12,
            offset: 6,
          }}
          wrapperCol={{
            span: 12,
            offset: 6,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Salary"
              name="salary"
              rules={[
                {
                  required: true,
                  message: "Please input numeric value!",
                  validator: validateNumericInput
                },
              ]}
            >
              <Input name="salary" placeholder="Enter your salary" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Start Date"
              name="salaryStartDate"
              rules={[
                {
                  required: true,
                  message: "Please input your start date!",
                },
              ]}
            >
              <DatePicker
                name="salaryStartDate"
                onChange={(date) => setsalaryStartDate(date)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="End Date"
              name="salaryEndDate"
            >
              <DatePicker
                defaultValue={initialValues.salaryEndDate}
                onChange={(date) => setsalaryEndDate(date)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "15px" }}
              label="Comment"
              name="salaryComment"
            >
              <Input name="salaryComment" value={salaryComment} onChange={(e)=>setsalaryComment(e.target.value)} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 12,
              }}
            >
              <Button
                onClick={() => setLoader(true)}
                type="submit"
                className="bg-[#E42976] text-white border-0"
                size="medium"
                htmlType="submit"
                block
                loading={loader}
              >
                Update Now
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default SalaryEditSinglePopup;
