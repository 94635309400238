import { Navigate } from "react-router-dom";
import PageTitle from "../../page-header/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Card, Table, Tag } from "antd";
// import AddTask from "./Addtask";
import UpdateBtn from "../../Buttons/UpdateBtn";
import { loadAllProjectTasks } from "../../../redux/rtk/features/projectManagement/project/projectTask/projectTask";
import DeleteBtn from "../../Buttons/DeleteBtn";
import ViewBtn from "../../Buttons/ViewBtn";
import dayjs from "dayjs";
import DeleteTask from "./DeleteTask";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { CsvLinkBtn } from "../../UI/CsvLinkBtn";
import ColVisibilityDropdown from "../../Shared/ColVisibilityDropdown";

const columns = [
  {
    id: 1,
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    id: 2,
    title: "Task Name",
    key: "name",
    render: (record) => {
      return typeof record === "object" ? record.name.toUpperCase() : record;
    },
  },
  {
    id: 3,
    title: "Start Date",
    key: "startDate",
    render: (record) => {
      return typeof record === "object"
        ? dayjs(record.startDate).utc().format("DD-MM-YYYY")
        : record;
    },
  },
  {
    id: 4,
    title: "End Date",
    key: "endDate",
    render: (record) => {
      return typeof record === "object"
        ? dayjs(record.endDate).utc().format("DD-MM-YYYY")
        : record;
    },
  },
  {
    id: 5,
    title: "Completion Time",
    key: "completionTime",
    render: (record) => {
      return typeof record === "object" ? record?.completionTime + " hr" : "-";
    },
  },
  {
    id: 6,
    title: "Priority",
    key: "priority",
    render: (record) => {
      return typeof record === "object" ? (
        <Tag
          color={
            record.priority.name === "Medium"
              ? "orange"
              : record.priority.name === "High"
              ? "red"
              : record.priority.name === "Low"
              ? "yellow"
              : "red"
          }
        >
          {record.priority.name}
        </Tag>
      ) : (
        record
      );
    },
  },
  {
    id: 7,
    title: "Task Status",
    key: "taskStatus",
    render: (record) => {
      return typeof record === "object" ? (
        <Tag
          color={
            record.taskStatus === "INPROGRESS"
              ? "orange"
              : record.taskStatus === "COMPLETED"
              ? "green"
              : record.taskStatus === "PENDING"
              ? "yellow"
              : "red"
          }
        >
          {record.taskStatus}
        </Tag>
      ) : (
        record
      );
    },
  },
  {
    title: "Update Status By",
    dataIndex: "updatedByUser",
    key: "updatedByUser",
    render: (updatedByUser) =>
      updatedByUser?.firstName == null
        ? "--"
        : updatedByUser?.firstName + " " + updatedByUser?.lastName,
  },
  {
    id: 8,
    title: "Action",
    dataIndex: "id",
    key: "action",
    render: (id) => (
      <div className="flex justify-start">
        <div className="flex justify-start">
          <ViewBtn path={`/admin/task/view/${id}`} />
        </div>
        <div className="flex justify-start">
          <DeleteTask id={id} />
        </div>
      </div>
    ),
  },
];

const Task = (props) => {
  const dispatch = useDispatch();
  const isLogged = Boolean(localStorage.getItem("isLogged"));
  const { loading, list } = useSelector((state) => state.projectTask);
  const [columnsToShow, setColumnsToShow] = useState([]);

  useEffect(() => {
    dispatch(loadAllProjectTasks());
  }, []);

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);
  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };
  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }
  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data.id,
          Task_Name: data.name == null ? "-" : data.name,
          startDate:
            data.startDate == null
              ? "-"
              : dayjs(data.startDate).format("YYYY-MM-DD"),
          EndDate:
            data.endDate == null
              ? "-"
              : dayjs(data.endDate).format("YYYY-MM-DD"),
          completionTime:
            data.completionTime == null ? "-" : data.completionTime,
          priority: data?.priority?.name == null ? "-" : data?.priority?.name,
          taskStatus: data.taskStatus == null ? "-" : data.taskStatus,
          updatedBy: data.updatedBy == null ? "-" : data.updatedBy,
        }))
      : "";

  return (
    <>
      <div className="mb-4">
        <h4 className="text-[24px] font-[600]">Task List</h4>
      </div>
      <div>
        <div className="text-center my-2 flex justify-between items-center mr-2 flex-wrap gap-4">
          <div className="flex items-center">
          {list && (
              <div>
                <ColVisibilityDropdown
                  options={columns}
                  columns={columns}
                  columnsToShowHandler={columnsToShowHandler}
                />
              </div>
            )}
            {list && (
              <div>
                <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                  <CSVLink
                    data={CSVlist}
                    className="btn btn-dark btn-sm hover:text-white text-[15px] font-[400]"
                    filename="Task-List"
                  >
                    CSV
                  </CSVLink>
                </CsvLinkBtn>
              </div>
            )}
          </div>
          <div>
            <Link
              to="/admin/add/task"
              className=" bg-[#E42976] text-white border-0 h-[30px] py-0 px-[25px] rounded-[5px] flex items-center text-[15px] font-[400]"
            >
              Add Task
            </Link>
          </div>
        </div>
        <div className="card ant-shadow mr-0 rounded-[20px] border-0">
          <div className="ant-card-body">
            <Table
              scroll={{ x: true }}
              loading={loading}
              pagination={{
                defaultPageSize: 20,
              }}
              columns={columnsToShow}
              dataSource={list} // Use list instead of dummyData
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
