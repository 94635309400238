import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    employee: null,
    department: [],
    error: "",
    loading: false,
    AllDepartments: []
};

/* // ADD_DESIGNATION
export const addDesignation = createAsyncThunk(
    "designation/addDesignation",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `designation/`,
                data: {
                    ...values,
                },
            });
            toast.success("designation Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding designation try again");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
); */
export const loadAllDepartment = createAsyncThunk("department/loadAllDepartment", async () => {
    try {
        const { data } = await axios.get(`department?status=true`);
        return data;
    } catch (error) {
        console.log(error.message);
    }
});
// DELETE_DESIGNATION
export const deleteDepartment = createAsyncThunk(
    "department/deleteDepartment",
    async (id, { rejectWithValue }) => {
        try {
            const resp = await axios({
                method: "patch",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `department/${id}`,
                data: {
                    status: false,
                },
            });
            toast.success("Department Deleted");
            return {
                data: resp.data.id,
                message: "success"
            };
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
                return rejectWithValue(error.response.data.message);
            } else {
                throw error;
            }
        }
    }
);

/* // DESIGNATION_DETAILS
export const loadSingleDesignation = createAsyncThunk(
    "designation/loadSingleDesignation",
    async (id) => {
        try {
            const data = await axios.get(`designation/employee/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
); */

// All Departments
export const GetAllDepartments = createAsyncThunk(
    "department/AllDepartment",
    async () => {
        try {
            const { data } = await axios.get(`department?query=all`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

/* export const loadAllDesignationByEmployee = createAsyncThunk(
    "designation/loadAllDesignationByEmployee",
    async () => {
        try {
            const { data } = await axios.get(`designation/employee`);

            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
); */

const departmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        clearDepartment: (state) => {
            state.department = null;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(loadAllDepartment.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllDepartment.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });






        // 1) ====== builders for loadAllDesignation ======

        builder.addCase(GetAllDepartments.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(GetAllDepartments.fulfilled, (state, action) => {
            state.loading = false;
            state.AllDepartments = action.payload;
        });

        builder.addCase(GetAllDepartments.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 2) ====== builders for loadAllDesignationByEmployee ======
        /* 
                builder.addCase(loadAllDesignationByEmployee.pending, (state) => {
                    state.loading = true;
                });
        
                builder.addCase(loadAllDesignationByEmployee.fulfilled, (state, action) => {
                    state.loading = false;
                    state.list = action.payload;
                });
        
                builder.addCase(loadAllDesignationByEmployee.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload.message;
                }); */

        // 2) ====== builders for addDesignation ======

        /*       builder.addCase(addDesignation.pending, (state) => {
                  state.loading = true;
              });
      
              builder.addCase(addDesignation.fulfilled, (state, action) => {
                  state.loading = false;
              });
      
              builder.addCase(addDesignation.rejected, (state, action) => {
                  state.loading = false;
                  state.error = action.payload.message;
              }); */

        // 3) ====== builders for loadSingleDesignation ======

        /*     builder.addCase(loadSingleDesignation.pending, (state) => {
                state.loading = true;
            });
    
            builder.addCase(loadSingleDesignation.fulfilled, (state, action) => {
                state.loading = false;
                state.designation = action.payload.data;
            });
    
            builder.addCase(loadSingleDesignation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            }); */

        // 4) ====== builders for deleteDesignation ======

        builder.addCase(deleteDepartment.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteDepartment.fulfilled, (state, action) => {
            state.loading = false;
            const filterCategory = state.list.filter(
                (category) => category.id !== parseInt(action.payload) && category
            );
            state.list = filterCategory;

            const filterDesignation = state.list.filter(
                (depart) => depart.id !== parseInt(action.payload) && depart
            );

            state.list = filterDesignation;
        });

        builder.addCase(deleteDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    },
});

export default departmentSlice.reducer;
export const { clearDepartment } = departmentSlice.actions;
