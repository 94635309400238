import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loadSingleStaff } from "../../../redux/rtk/features/user/userSlice";
import { addDesHistory } from "../../designationHistory/designationHistoryApis";
import { loadAllDesignation } from "../../../redux/rtk/features/designation/designationSlice";

const DesignationAddSinglePopup = ({ list, setLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [designationStartDate, setdesignationStartDate] = useState(null);
  const [designationEndDate, setdesignationEndDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [designationId, setDesignationId] = useState("");

  const designations = useSelector((state) => state.designations.list);

  const user_id = useParams("id");
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoader(true);
    setLoading(true);
      console.log(designationEndDate, "designationEndDate")
    const infodata = {
      ...values,
      userId: parseInt(user_id.id),
      designationId: parseInt(designationId),
      designationStartDate: dayjs(designationStartDate).format("YYYY-MM-DD"),
        designationEndDate: designationEndDate ? dayjs(designationEndDate).format("YYYY-MM-DD") : null, // Conditionally set end date
    };

    const resp = await addDesHistory(infodata);

    if (resp.message === "success") {
      setLoader(false);
      dispatch(loadSingleStaff(user_id.id));
      setIsModalOpen(false);
      setLoading(false);
      form.resetFields();
    } else {
      setLoader(false);
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
    setLoading(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setdesignationStartDate(dayjs());
    setdesignationEndDate(null);
    setIsModalOpen(false);
    setLoader(false);
    setLoading(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setdesignationStartDate(dayjs());
    setdesignationEndDate(dayjs());
    setIsModalOpen(false);
    setLoader(false);
    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    dispatch(loadAllDesignation());
  }, []);

  return (
    <>
      <div className="text-center">
        <Button type="button" className="bg-[#E42976] text-white border-0" onClick={showModal}>
          Add New Designation
        </Button>
      </div>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Designation
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="global-modal-custom "
      >
        <Form
          form={form}
          style={{ marginBottom: "50px" }}
          eventKey="department-form"
          layout="vertical"
          name="basic"
          labelCol={{
            xs: { offset: 0, span: 12 },
            sm: { offset: 0, span: 12 },
            md: { offset: 6, span: 12 },
            lg: { offset: 6, span: 12 },
            xl: { offset: 6, span: 12 },
          }}
          wrapperCol={{
            xs: { offset: 0, span: 12 },
            sm: { offset: 0, span: 12 },
            md: { offset: 6, span: 12 },
            lg: { offset: 6, span: 12 },
            xl: { offset: 6, span: 12 },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Designation"
              name="designationId"
              rules={[
                {
                  required: true,
                  message: "Please input your Designation!",
                },
              ]}
            >
              <Select
                loading={!list}
                placeholder="Select Designation"
                onChange={(value) => setDesignationId(value)}
              >
                {list?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name || ""}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Start Date"
              name="designationStartDate"
              valuePropName="designationStartDate"
              rules={[
                { required: true, message: "Please input your start date!" },
                () => ({
                  validator(_, value) {
                    if (!value || value.isBefore(dayjs().add(1, 'day').startOf('day'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Start date cannot be in the future!'));
                  },
                }),
              ]}
            >
              <DatePicker onChange={(date) => setdesignationStartDate(date)} />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="End Date"
              name="designationEndDate"
              valuePropName="designationEndDate"
            >
              <DatePicker
                  onChange={(date) => setdesignationEndDate(date ? date : null)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Comment"
              name="designationComment"
            >
              <Input placeholder="Comment" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              wrapperCol={{
                xs: { offset: 0, span: 12 },
                sm: { offset: 0, span: 12 },
                md: { offset: 6, span: 12 },
                lg: { offset: 6, span: 12 },
                xl: { offset: 6, span: 12 },
              }}
            >
              <Button
                onClick={() => setLoader(true)}
                type="submit"
                 className="bg-[#E42976] text-white border-0"
                size="medium"
                htmlType="submit"
                block
                loading={loader}
              >
                Add Now
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default DesignationAddSinglePopup;
