import { Card, List, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllAnnouncement } from "../../redux/rtk/features/announcement/announcementSlice";
import AnnouncementDelete from "./AnnouncementDelete";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { CSVLink } from "react-csv";

const TitleComponent = ({ item }) => {
  return (
    <div className="flex justify-between">
      <h2 className="text-xl txt-color-2 truncate">
        Title: <br /> {item.title}
      </h2>
      <div className="flex justify-end">
        <UserPrivateComponent permission={"delete-announcement"}>
          <AnnouncementDelete id={item.id} />
        </UserPrivateComponent>
      </div>
    </div>
  );
};

const GetAllAnnouncement = () => {
  const { loading, list } = useSelector((state) => state.announcement);
  const dispatch = useDispatch();
  const [columnsToShow, setColumnsToShow] = useState([]);

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      id: 3,
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id, data) => (
        <>
          <div className="flex items-center">
            {/* <UserPrivateComponent permission={"readSingle-leavePolicy"}>
              <ViewBtn path={`/admin/leave-policy/${id}/`} />
            </UserPrivateComponent>
            <UserPrivateComponent permission={"update-leavePolicy"}>
              <LeavePolicyEdit data={data} tid={id} size={25} />
            </UserPrivateComponent> */}
            <UserPrivateComponent permission={"delete-announcement"}>
              <AnnouncementDelete id={id} />
            </UserPrivateComponent>
          </div>
        </>
      ),
    },
  ];

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };
  useEffect(() => {
    dispatch(loadAllAnnouncement());
  }, []);

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);
  return (
    <>
      <div className="text-center my-2 flex justify-between flex-wrap items-center px-2">
        <div className="flex items-center">
          <div>
            <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
          </div>
          {list && (
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={list}
                  className="btn btn-dark btn-sm text-white text-[15px] font-[400]"
                  filename="Announcement"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          )}
        </div>
      </div>
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={!list || loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
        {/* <List
          className="m-4"
          loading={loading}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={list ? list : []}
          renderItem={(item) => (
            <List.Item className="new-card">
              <Card
                bordered={false}
                className="ant-shadow rounded-[20px] p-0 mx-2"
                title={<TitleComponent item={item} />}
              >
                <div style={{ lineBreak: "anywhere" }}>
                  Description:
                  <br />
                  {item.description}
                </div>
              </Card>
            </List.Item>
          )}
        /> */}
      </Card>
    </>
  );
};
export default GetAllAnnouncement;
