import "bootstrap-icons/font/bootstrap-icons.css";
import { useState } from "react";
import { Table, Tag } from "antd";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";

import dayjs from "dayjs";
import BtnViewSvg from "../UI/Button/btnViewSvg";
import ViewBtn from "../Buttons/ViewBtn";
import { loadSingleLeaveHistory } from "../../redux/rtk/features/leave/leaveSlice";
import { useParams } from "react-router-dom";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function CustomTable({ list, loading }) {
	const [columnsToShow, setColumnsToShow] = useState([]);
console.log(list, "list....");

const columns = [
  {
    id: 1,
    title: "ID",
    dataIndex: "id",
    key: "id",
  },

  {
    id: 3,
    title: "Leave Type",
    dataIndex: "leaveType",
    key: "leaveType",
  },
  {
    id: 4,
    title: "Leave From",
    dataIndex: "leaveFrom",
    key: "leaveFrom",
    render: (leaveFrom) => dayjs(leaveFrom).format("DD-MM-YYYY"),
  },
  {
    id: 5,
    title: "Leave To",
    dataIndex: "leaveTo",
    key: "leaveTo",
    render: (leaveTo) => dayjs(leaveTo).format("DD-MM-YYYY"),
  },
  {
    id: 6,
    title: "Leave Duration",
    dataIndex: "leaveDuration",
    key: "leaveDuration",
    render: (leaveDuration) => {
      if (leaveDuration > 1) {
        return <span>{leaveDuration} days</span>;
      } else {
        return <span>{leaveDuration} day</span>;
      }
    },
  },

  {
    id: 7,
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      if (status === "ACCEPTED") {
        return <Tag color="green">{status.toUpperCase()}</Tag>;
      } else if (status === "REJECTED") {
        return <Tag color="red">{status.toUpperCase()}</Tag>;
      } else {
        return <Tag color="orange">{status.toUpperCase()}</Tag>;
      }
    },
  },

  {
    id: 7,
    title: "Applied On",
    dataIndex: "createdAt",
    render: (createdAt) => dayjs(createdAt).format("DD-MM-YYYY"),
  },

  {
    id: 7,
    title: "Action",
    key: "action",
    render: ({ id }) => (
      <ViewBtn
        path={`/admin/my-leave/${id}`}
        text="View"
        icon={<BtnViewSvg />}
      />
    ),
  },
];

useEffect(() => {
  setColumnsToShow(columns);
}, []);

const columnsToShowHandler = (val) => {
  setColumnsToShow(val);
};

const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

const CSVlist =
  Array.isArray(list) && list.length > 0
    ? list?.map((data) => ({
        id: data.id,
        Leave_Type: data.leaveType == null ? "-" : data.leaveType,
        leave_From:
          data.leaveFrom == null
            ? "-"
            : dayjs(data.leaveFrom).utc().format("DD-MM-YYYY"),
        leaveTo:
          data.leaveTo == null
            ? "-"
            : dayjs(data.leaveTo).utc().format("DD-MM-YYYY"),
        leaveDuration:
          data.leaveDuration == null
            ? "-"
            : data.leaveDuration > 1
            ? data.leaveDuration + " days"
            : data.leaveDuration == "0.5"
            ? "half"
            : data.leaveDuration + " day",
        status: data.status == null ? "--" : data.status,
        Applied_On:
          data?.createdAt == null
            ? "--"
            : dayjs(data?.createdAt).format("DD-MM-YYYY"),
      }))
    : "";

return (
  <div className="ant-card pl-4 py-3 rounded mt-5">
    <div className="w-50">
      <h4 className="text-[24px] font-[600] mb-2"> My Leave Applications</h4>
    </div>
    <div className="flex my-2 justify-between items-center mr-4 flex-wrap gap-4">
      {list && (
        <div className="flex justify-end mr-6">
          <div>
            {list && list.length > 0 ?
            <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
              <CSVLink
                data={CSVlist}
                className="btn btn-dark btn-sm hover:text-white text-[15px] font-[400]"
                style={{ marginTop: "5px" }}
                filename="leave_applications"
              >
                Download CSV
              </CSVLink>
            </CsvLinkBtn>
            :
            <div >no data to export</div>
            }
          </div>
        </div>
      )}
      {list && (
        <div>
          <ColVisibilityDropdown
            options={columns}
            columns={columns}
            columnsToShowHandler={columnsToShowHandler}
          />
        </div>
      )}
    </div>
    <div className="card border-0 ant-shadow">
      <div className="ant-card-body">
        <Table
          className="text-center"
          scroll={{ x: true }}
          loading={loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </div>
    </div>
  </div>
);
}

const UserLeave = (props) => {
	const { id } = useParams("id");
	const dispatch = useDispatch();
	const list = useSelector((state) => state.leave.leaveHistory);
	const loading = useSelector((state) => state.leave.loading);

	useEffect(() => {
		dispatch(loadSingleLeaveHistory(id));
	}, []);

	return (
		<UserPrivateComponent permission={"readSingle-leaveApplication"}>
			<div className='card border-0'>
				<div className='card-body'>
					<CustomTable list={list?.singleLeave} loading={loading} />
				</div>
			</div>
		</UserPrivateComponent>
	);
};

export default UserLeave;
