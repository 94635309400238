import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
	deleteAnnouncement,
	loadAllAnnouncement,
} from "../../redux/rtk/features/announcement/announcementSlice";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

const AnnouncementDelete = ({ id }) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [delModal, setDelModal] = useState(false)

	const onDelete = async () => {
		setDelModal(true)
	};

	return (
		<>
			<button
				type='primary'
				onClick={onDelete}
				className={`mr-3 ml-2 ${loading ? "animate-spin" : ""}`}>
				<MdDelete size={25} color="#FF0000" />
			</button>
			<DelModal
				open={delModal}
				onClose={setDelModal}
				AnnouncementId={id}
				content={"Are you sure to delete this Announcement?"}
				type={"Announcement"}
			/>
		</>

	);
};
export default AnnouncementDelete;
