import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Col,
	Input,
	Row,
	Typography,
	Form,
	Select,
	DatePicker,
	TimePicker,
} from "antd";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
	addManualAttendance,
	loadAllAttendance,
} from "../../redux/rtk/features/attendance/attendanceSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import GetAllAttendance from "./GetAllAttendance";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const { Title } = Typography;

const Attendance = ({ drawer }) => {
	const [loader, setLoader] = useState(false);
	const users = useSelector((state) => state.users?.list);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const [inTimeDate, setInTimeDate] = useState({
		time: null,
		date: null,
	});
	const [outTimeDate, setOutTimeDate] = useState({
		time: null,
		date: null,
	});

	useEffect(() => {
		dispatch(loadAllStaff());
	}, []);

	const onFinish = async (values) => {
		const FormData = {
			...values,
			inTime: inTimeDate.date && inTimeDate.time ? new Date(inTimeDate.date + " " + inTimeDate.time) : null,
			outTime: outTimeDate.date && outTimeDate.time ? new Date(outTimeDate.date + " " + outTimeDate.time) : null,
		};
		setLoader(true);
		const resp = await dispatch(addManualAttendance(FormData));
		if (resp.payload.message === "success") {
			setLoader(false);
			form.resetFields();
			setInTimeDate({});
			setOutTimeDate({});
			dispatch(loadAllAttendance());
		} else {
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding shift");
		setLoader(false);
	};

	return (
		<Fragment>
			<UserPrivateComponent permission={"create-attendance"}>
				<Row className="mr-top" justify={drawer ? "center" : "space-between"}>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={drawer ? 22 : 12}
						xl={drawer ? 22 : 12}
						className="column-design border rounded card-custom"
					>
						<Title level={4} className="m-2 mt-5 mb-5 text-center">
							Add Manual Attendance
						</Title>
						{(!inTimeDate.time || !inTimeDate.date || !outTimeDate.time || !outTimeDate.date) && (
							<p className="text-center text-rose-500 text-sm font-medium mb-4">
								* Please fill Date and Time
							</p>
						)}
						<Form
							form={form}
							style={{ marginBottom: "40px" }}
							eventKey="shift-form"
							name="basic"
							labelCol={{
								span: 6,
							}}
							wrapperCol={{
								span: 12,
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<div>
								<Form.Item
									style={{ marginBottom: "10px" }}
									label="User"
									name="userId"
									rules={[
										{
											required: true,
											message: "Please select a user!",
										},
									]}
								>
									<Select placeholder="Select User">
										{users?.map((user) => (
											<Select.Option key={user.id} value={user.id}>
												{user.userName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>

								{/* Start Time */}
								<Form.Item
									style={{ marginBottom: "10px" }}
									label="Start Time"
									rules={[
										{
											required: true,
											message: "Please input start time!",
										},
									]}
								>
									<div className="flex justify-between">
										<DatePicker
											format="YYYY-MM-DD"
											onChange={(date, dateString) =>
												setInTimeDate({ ...inTimeDate, date: dateString })
											}
										/>
										<TimePicker
											className="ml-4"
											format="HH:mm:ss"
											onChange={(time, timeString) =>
												setInTimeDate({ ...inTimeDate, time: timeString })
											}
										/>
									</div>
								</Form.Item>

								{/* End Time */}
								<Form.Item
									style={{ marginBottom: "10px" }}
									label="End Time"
									rules={[
										{
											required: true,
											message: "Please input end time!",
										},
									]}
								>
									<div className="flex justify-between">
										<DatePicker
											format="YYYY-MM-DD HH:mm:ss"
											onChange={(date, dateString) =>
												setOutTimeDate({ ...outTimeDate, date: dateString })
											}
										/>
										<TimePicker
											className="ml-4"
											format="HH:mm:ss"
											onChange={(time, timeString) =>
												setOutTimeDate({ ...outTimeDate, time: timeString })
											}
										/>
									</div>
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									label="Comment"
									name="comment"
								>
									<Input placeholder="Comment" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									label="IP Address"
									name="ip"
								>
									<Input placeholder="127.0.0.1" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									wrapperCol={{
										offset: 6,
										span: 12,
									}}
								>
									<Button
										onClick={() => setLoader(true)}
										type="primary"
										size="large"
									
										htmlType="submit"
										block
										loading={loader}
									>
										Add Attendance
									</Button>
								</Form.Item>
							</div>
						</Form>
					</Col>
				</Row>
			</UserPrivateComponent>
			{/* <UserPrivateComponent permission={"readAll-attendance"}>
				<GetAllAttendance />
			</UserPrivateComponent> */}
		</Fragment>
	);
};

export default Attendance;
