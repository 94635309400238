import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updateEmploymentStatus } from "../../../redux/rtk/features/employemntStatus/employmentStatusSlice";

const EmploymentStatusEditPopup = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.employmentStatus);
  const navigate = useNavigate();
  const onFinish = (values) => {
    dispatch(updateEmploymentStatus({ id: id, values: values }));
    setIsModalOpen(false);
    navigate(-1);
  };

  const [initialValues, setInitialValues] = useState({
    name: data?.name || "",
    colourValue: data?.colourValue,
    description: data?.description,
  });

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button onClick={showModal}>
        <AiOutlineEdit size={30} color="#23B9F8" />
      </button>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Edit Employement Status
          </div>
        }
        className="global-modal-custom"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          style={{ marginBottom: "40px" }}
          eventKey="shift-form"
          name="basic"
		  layout="vertical"
          initialValues={initialValues}
          labelCol={{
            span: 12,
			offset:6
          }}
          wrapperCol={{
            span: 12,
			offset:6
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your shift!",
                },
              ]}
            >
              <Input placeholder="Parmanet" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Color Code"
              name="colourValue"
              rules={[
                {
                  required: true,
                  message: "Please input your shift!",
                },
              ]}
            >
              <Input placeholder="#00FF00" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              label="Description"
              name={"description"}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              wrapperCol={{
                offset: 6,
                span: 12,
              }}
            >
              <Button
                type="submit"
                className="bg-[#E42976] text-white border-0"
                size="medium"
                htmlType="submit"
                block
                loading={loading}
              >
                Update Now
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default EmploymentStatusEditPopup;
