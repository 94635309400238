import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Popover } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import CustomTable from "./CustomTable";
import { loadSingleRole } from "./roleApis";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import DeleteRole from "./DeleteRole";
import styles from "./styles.module.css";
//PopUp

const DetailRole = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  //dispatch
  const dispatch = useDispatch();
  const [role, setRole] = useState(null);

  //Delete Supplier
  const onDelete = () => {
    try {
      setVisible(false);
      //dispatch(deleteRole(id));
      toast.warning(`role Name : ${role.rolename} is removed `);
      return navigate("/admin/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  };
  // Delete Supplier PopUp
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    loadSingleRole(id).then((d) => setRole(d.data));
  }, [id]);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <PageTitle title=" Back  " />
      <UserPrivateComponent permission={"readSingle-role"}>
        <div className="mr-top">
          {role ? (
            <Fragment key={role.id}>
              <div className="flex justify-between mb-5">
                <h5>
                  <i className="bi bi-person-lines-fill"></i>
                  <span className="mr-left">
                    ID : {role.id} | {role.name}
                  </span>
                </h5>
                <div className="text-end">
                  {/* <UserPrivateComponent permission={"delete-role"}>
											<DeleteRole id={id} />
										</UserPrivateComponent> */}
                  <UserPrivateComponent permission={"update-role"}>
                    <Link
                      className="m-2"
                      to={`/admin/role/permit/${role.id}`}
                      state={{ data: role }}
                    >
                      <Button
                        type="button"
                        className="bg-[#E42976] text-white border-0"
                        shape="round"
                        icon={<EditOutlined />}
                      >
                        {" "}
                        New Permission{" "}
                      </Button>
                    </Link>
                  </UserPrivateComponent>
                </div>
              </div>

              <Card bordered={false} className={styles.cardBody}>
                <CustomTable role={role?.rolePermission} />
              </Card>
            </Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </UserPrivateComponent>
    </div>
  );
};

export default DetailRole;
