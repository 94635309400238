import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const EmployeeLineChart = ({ EmployeeData }) => {
    return (
        <ResponsiveContainer height={110}>
        <LineChart
            data={EmployeeData}
            margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
            }}
            
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend layout="horizontal" />
            <Line  type="monotone" dataKey="ThisMonth" stroke="#4CF552" />
            <Line type="monotone" dataKey="LastMonth" stroke="#0095DE" />
        </LineChart>
    </ResponsiveContainer>
    );
};

export default EmployeeLineChart;
