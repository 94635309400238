import { useEffect, useState } from "react";
import { Col, Divider, Row, Card } from "antd";
import styles from "./leave.module.css";
import { AiOutlineUser } from "react-icons/ai";
import { DateRange, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import LeaveLineChart from "./LineChart";
import LeaveStatsBarChart from "./Charts";
import axios from "axios";
import {
  countLeaveApplication,
  calenderLeave,
} from "../../redux/rtk/features/leave/leaveSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTodayLeaveData,
  fetchYearsLeaveData,
} from "../../redux/rtk/features/leave/leaveSlice";
import UserDetailsModal from "./UserDetailsModal";
import dayjs from "dayjs";

const LeaveStates = () => {
  const dispatch = useDispatch();
  const [userModal, setUserModal] = useState(false);
  const leaveDaily = useSelector((state) => state.leave.dailyData);
  const leaveYearly = useSelector((state) => state.leave.yearlyData);
  const calenderHistory = useSelector(
    (state) => state.leave.calenderLeaveHistory
  );

  useEffect(() => {
    dispatch(fetchTodayLeaveData());
    dispatch(fetchYearsLeaveData());
  }, [dispatch]);

  const transformedDataLine = Object.keys(leaveDaily?.weekCounts || {}).map(
    (day) => {
      return {
        name: day,
        Accepted: leaveDaily.weekCounts[day].approved || 0,
        Rejected: leaveDaily.weekCounts[day].rejected || 0,
        Pending: leaveDaily.weekCounts[day].pending || 0,
      };
    }
  );

  const transformedBarData = Object.keys(leaveYearly?.yearCounts || {}).map(
    (y) => {
      return {
        name: leaveYearly.yearCounts[y].month || 0,
        approved: leaveYearly.yearCounts[y].approved || 0,
        rejected: leaveYearly.yearCounts[y].rejected || 0,
      };
    }
  );
  const tableHeaderStyle = {
    backgroundColor: "white",
    color: "#646366",
  };

  function handleSelect(date) {
    console.log(date);
    dispatch(calenderLeave(dayjs(date).format("YYYY-MM-DD")));
    setUserModal(true);
  }

  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card className={`${styles.cardBody}`}>
            <div className={`${styles.TitleStyles} mx-3`}>
              <h2>Leave Stats</h2>
              <h5>Leave Summary</h5>
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className={styles.Style}>
                  <div className={styles.Div}>
                    <AiOutlineUser className={styles.icon} />
                    <p>
                      Today's <br /> Leave{" "}
                    </p>
                    <h2>{leaveDaily ? leaveDaily.totalLeaves : 0}</h2>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className={styles.StyleA}>
                  <div className={styles.Div}>
                    <AiOutlineUser className={styles.iconA} />
                    <p>
                      {" "}
                      Leave
                      <br />
                      Approved
                    </p>
                    <h2>{leaveDaily ? leaveDaily.totalApproved : 0}</h2>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className={styles.StyleR}>
                  <div className={styles.Div}>
                    <AiOutlineUser className={styles.iconR} />
                    <p>
                      {" "}
                      Leave
                      <br />
                      Reject
                    </p>
                    <h2>{leaveDaily ? leaveDaily.totalRejected : 0}</h2>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className={styles.StyleP}>
                  <div className={styles.Div}>
                    <AiOutlineUser className={styles.iconP} />
                    <p>
                      {" "}
                      Leave
                      <br />
                      Pending
                    </p>
                    <h2>{leaveDaily ? leaveDaily.totalPending : 0}</h2>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card className={`${styles.cardBody}`}>
            <div className={`${styles.TitleStyles} mx-3`}>
              <h2>Leave Insights (Daily)</h2>
              <div>
                <LeaveLineChart dataLine={transformedDataLine} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          <Card
            className={`${styles.cardBody}`}
            style={{ width: "100%", height: "438px" }}
          >
            <div className={`${styles.TitleStyles} flex justify-between`}>
              <div>
                <h2>Staff on Leave</h2>
                <h5>Staff Leave Summary</h5>
              </div>
              <UserDetailsModal
                userData={calenderHistory?.data?.approvedLeave}
                style={tableHeaderStyle}
                open={userModal}
                onclose={setUserModal}
              />
            </div>

            <div className="text-center">
              <Calendar date={new Date()} onChange={(e) => handleSelect(e)} />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <Card className={`${styles.cardBody}`}>
            <div className={`${styles.TitleStyles} `}>
              <h2>Monthly Leave Status</h2>
            </div>
            <div className="mt-3">
              <LeaveStatsBarChart
                width="100%"
                height={300}
                transformedBarData={transformedBarData}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default LeaveStates;
