import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Table,
  Typography,
  Modal,
} from "antd";
import LeavePolicyEdit from "../UI/PopUp/LeavePolicyEditPopup";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleLeavePolicy,
  loadAllLeavePolicy,
} from "../../redux/rtk/features/leavePolicy/leavePolicySlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

function CustomTable({ list, loading }) {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [addpolicy, setAddPolicy] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [policyid, setPolicyid] = useState(null);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Total Paid Leave",
      dataIndex: "paidLeaveCount",
      key: "paidLeaveCount",
    },

    {
      id: 3,
      title: "Total Unpaid Leave",
      dataIndex: "unpaidLeaveCount",
      key: "unpaidLeaveCount",
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id, data) => (
        <>
          <div className="flex items-center">
            <UserPrivateComponent permission={"readSingle-leavePolicy"}>
              <ViewBtn path={`/admin/leave-policy/${id}/`} />
            </UserPrivateComponent>
            <UserPrivateComponent permission={"update-leavePolicy"}>
              <LeavePolicyEdit data={data} tid={id} size={25} />
            </UserPrivateComponent>
            <UserPrivateComponent permission={"delete-leavePolicy"}>
              <button className="ml-4 mr-2" onClick={() => onDelete(id)}>
                <MdDelete size={25} color="#FF0000" />
              </button>
            </UserPrivateComponent>
          </div>
        </>
      ),
    },
  ];
  //Delete Supplier
  const onDelete = (id) => {
    console.log(id, "del id");
    if (id) {
      setPolicyid(id);
      setPolicyModal(true);
    }
  };
  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const handleOk = () => {
    setAddPolicy(false);
  };

  const { Title } = Typography;

  const onFinish = async (values) => {
    const FormData = {
      ...values,
      paidLeaveCount: parseInt(values.paidLeaveCount),
      unpaidLeaveCount: parseInt(values.unpaidLeaveCount),
    };
    setLoader(true);
    const resp = await dispatch(addSingleLeavePolicy(FormData));
    if (resp.payload.message === "success") {
      setLoader(false);
      setAddPolicy(false);
      form.resetFields();
      dispatch(loadAllLeavePolicy());
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
  };
  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-2">
        Leave Policy List
      </h5>
      <div className="text-center my-2 flex justify-between flex-wrap items-center px-2">
        <div className="flex items-center">
          <div>
            <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
          </div>
          {list && (
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={list}
                  className="btn btn-dark btn-sm text-white text-[15px] font-[400]"
                  filename="leave-policy"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          )}
        </div>
        <div>
          <Button
            className="text-[15px] font-[400] bg-[#E42976] text-white border-0"
            type="button"
            size="medium"
            onClick={(e) => setAddPolicy(true)}
          >
            Add New Policy
          </Button>
        </div>
      </div>

      <div className="card border-0 rounded-[15px] ant-shadow mr-0">
        <div className="ant-card-body">
          <Table
            scroll={{ x: true }}
            loading={!list || loading}
            columns={columnsToShow}
            dataSource={list ? addKeys(list) : []}
          />
        </div>
      </div>

      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Leave Policy
          </div>
        }
        open={addpolicy}
        onOk={handleOk}
        onCancel={() => setAddPolicy(false)}
        footer={null}
        className="global-modal-custom"
      >
        <UserPrivateComponent permission={"create-leavePolicy"}>
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                style={{ marginBottom: "40px" }}
                form={form}
                layout="vertical"
                eventKey="Leave-Policy"
                name="basic"
                labelCol={{
                  offset: 6,
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                  offset: 6,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your leave-policy name!",
                      },
                    ]}
                  >
                    <Input placeholder="Policy 10-12" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Paid Leave "
                    name="paidLeaveCount"
                    rules={[
                      {
                        required: true,
                        message: "Please input your paid leave!",
                      },
                    ]}
                  >
                    <Input placeholder="20" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "15px" }}
                    label="Unpaid Leave "
                    name="unpaidLeaveCount"
                    rules={[
                      {
                        required: true,
                        message: "Please input your unpaid Leave !",
                      },
                    ]}
                  >
                    <Input placeholder="10" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      offset: 6,
                      span: 12,
                    }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="submit"
                      className="bg-[#E42976] text-white border-0"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Add New Policy
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
      <DelModal
        open={policyModal}
        onClose={setPolicyModal}
        LeavePolicyId={policyid}
        content={"Are you sure to delete this Leave Policy?"}
        type={"DelPolicytable"}
      />
    </>
  );
}

const AddLeavePolicy = ({ drawer }) => {
  const { list, loading } = useSelector((state) => state.leavePolicy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllLeavePolicy());
  }, []);

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-leavePolicy"}>
        {drawer || <CustomTable list={list} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddLeavePolicy;
