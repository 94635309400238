import React, { useState } from 'react';
import { Modal, Table } from 'antd';
import 'antd/dist/antd.css';
import dayjs from "dayjs";

const UserTableModal = ({ userData,open,onclose }) => {

  console.log(userData,'userData')

  const handleOk = () => {
  };


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex:'user',
      key:'user',
      render: (user)=>(user?.firstName + " " + user?.lastName)
    },
    {
      title: 'Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render:(startDate)=>(dayjs(startDate).format('DD-MM-YYYY'))
    },
    {
      title: 'Department',
      dataIndex:'user',
      key:'user',
      render:(user)=>(user?.department?.name)
    },
  ];

  return (
    <div>
      <Modal
        footer={null}
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Staff on Leave
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={(e) => onclose(!open)}
        className="global-modal-custom"
      >
        <Table dataSource={userData} columns={columns} />
      </Modal>
    </div>
  );
};

export default UserTableModal;
