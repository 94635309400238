import { Modal } from "antd";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import Loader from "../../loader/loader";
import EmployeeSalary from "../EmployeeSalary";
import SalaryAddSinglePopup from "./SalaryAddPopup";

const SalaryEditPopup = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
    <>
      <button onClick={showModal} className="mt-4 mr-2">
      <AiOutlineEdit size={25} className=" text-[#23B9F8]" />
      </button>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Edit Salary
          </div>
        }
        className="global-modal-custom"
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!loading ? (
          <>
            <EmployeeSalary list={data} edit={true} setLoading={setLoading} />
            <SalaryAddSinglePopup setLoading={setLoading} />
          </>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};
export default SalaryEditPopup;
