import {
  ClockCircleOutlined,
  NotificationFilled,
  FlagFilled,
  HomeOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  PushpinOutlined,
  RocketOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  FileProtectOutlined,
  CheckCircleOutlined ,
  FileSyncOutlined,
  FileAddOutlined,
  SwapOutlined,
  BankOutlined,
  ContactsOutlined,
  DotChartOutlined,
  CarryOutOutlined,
  ExceptionOutlined,
  ReconciliationOutlined,
  VideoCameraAddOutlined,
  FundProjectionScreenOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { disable } from "workbox-navigation-preload";
import getPermissions from "../../utils/getPermissions";
import getUserFromToken from "../../utils/getUserFromToken";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
// import styles from "./Sidenav.module.css";
import { LiaUsersCogSolid } from "react-icons/lia";
import { LuCalendarClock,LuScreenShare} from "react-icons/lu";
import { PiArrowCounterClockwise } from "react-icons/pi";
import { MdOutlineMeetingRoom   } from "react-icons/md";
import { FaChalkboardTeacher,FaTasks  } from "react-icons/fa";


const Sidenav = ({ color, sideNavOpenKeys }) => {
  const users = useSelector((state) => state.users?.list);
  const dispatch = useDispatch();
  //const { id } = useParams();
  const user = getUserFromToken();
  const permissions = getPermissions();
  const hasPermission = (item) => {
    return permissions?.includes(item ? item : "");
  };
  useEffect(() => {
    dispatch(loadAllStaff());
  }, []);

  const menu = [
    {
      label: (
        <NavLink to="/admin/dashboard">
          <span className="text-white">Dashboard</span>
        </NavLink>
      ),
      key: "dashboard",
      icon: <HomeOutlined className="text-white" />,
    },
    (hasPermission("delete-employmentStatus") ||
      hasPermission("readAll-employmentStatus") ||
      hasPermission("create-user") ||
      hasPermission("create-location") ||
      hasPermission("readAll-user") ||
      hasPermission("readAll-role") ||
      hasPermission("readAll-designation") ||
      hasPermission("readAll-department") ||
      hasPermission("readAll-location")) && {
      label: <span className="text-white">HR Management</span>,
      key: "hr",
      icon: <LiaUsersCogSolid size={17} className="text-white" />,
      children: [
        hasPermission("create-user") && {
          label: (
            <NavLink to="/admin/hr/staffs/new">
              <span className="text-dark">New Employee</span>
            </NavLink>
          ),

          key: "staffs",
          icon: <UsergroupAddOutlined className="text-white" />,
        },
        hasPermission("readAll-user") && {
          label: (
            <NavLink to="/admin/hr/staffs">
              <span className="text-dark">Employee List</span>
            </NavLink>
          ),
          key: "users",
          icon: <SolutionOutlined className="text-white" />,
        },
        hasPermission("readAll-role") && {
          label: (
            <NavLink to="/admin/role">
              <span>Role & Permissions</span>
            </NavLink>
          ),
          key: "roleAndPermissions",
          icon: <UserSwitchOutlined className="text-dark" />,
        },
        hasPermission("readAll-designation") && {
          label: (
            <NavLink to="/admin/designation/">
              <span>Designation</span>
            </NavLink>
          ),
          key: "designation",
          icon: <UserSwitchOutlined className="text-white" />,
        },
        hasPermission("readAll-department") && {
          label: (
            <NavLink to="/admin/department">
              <span>Department</span>
            </NavLink>
          ),
          key: "department",
          icon: <UsergroupAddOutlined className="text-white" />,
        },
        /* 	hasPermission("create-location") && */ {
          label: (
            <NavLink to="/admin/location">
              <span>Location</span>
            </NavLink>
          ),
          key: "location",
          icon: <PushpinOutlined className="text-dark" />,
        },
        hasPermission("readAll-employmentStatus") && {
    			label: (
    				<NavLink to='/admin/employment-status'>
    					<span>Employement Status</span>
    				</NavLink>
    			),
    			key: "employementStatus",
    			icon: <FileDoneOutlined className="text-dark" />,
    		},
      ],
    },

    (hasPermission("create-attendance") ||
      hasPermission("readAll-attendance")) && {
      label: "Attendance",
      key: "attendance",
      icon: <ClockCircleOutlined className="text-white" />,
      children: [
        /* 	hasPermission("create-attendance") && {
						label: (
							<NavLink to='/admin/attendance'>
								<span>Attendance</span>
							</NavLink>
						),
						key: "attendance",
						icon: <FileDoneOutlined className="text-dark"/>,
					}, */
        hasPermission("readAll-attendance") && {
          label: (
            <NavLink to={`/admin/attendance/loadall`}>
              <span>All Attendance</span>
            </NavLink>
          ),
          key: "attendance",
          icon: <FileProtectOutlined  className="text-white" />,
        },
        hasPermission("readSingle-attendance") && {
          label: (
            <NavLink to={`/admin/attendance/user/${user}`}>
              <span>My Attendance</span>
            </NavLink>
          ),
          key: "myAttendance",
          icon: <CheckCircleOutlined className="text-white" />,
        },
      ],
    },

    // (hasPermission("create-payroll") || hasPermission("readAll-payroll")) && {
    // 	label: "PAYROLL",
    // 	key: "payroll",
    // 	icon: <WalletOutlined className="text-white" />,
    // 	children: [
    // 		hasPermission("create-payroll") && {
    // 			label: (
    // 				<NavLink to='/admin/payroll/new'>
    // 					<span>Calculate Payroll</span>
    // 				</NavLink>
    // 			),
    // 			key: "calculatePayroll",
    // 			icon: <FileDoneOutlined className="text-dark"/>,
    // 		},
    // 		hasPermission("readAll-payroll") && {
    // 			label: (
    // 				<NavLink to='/admin/payroll/list'>
    // 					<span>Payslip List</span>
    // 				</NavLink>
    // 			),
    // 			key: "payslipList",
    // 			icon: <FileOutlined className="text-dark" />,
    // 		},
    // 	],
    // },

    // hasPermission("readAll-shift") && {
    //   label: "Shift",
    //   key: "shift",
    //   icon: <ClockCircleOutlined />,
    //   children: [
    //     hasPermission("readAll-shift") && {
    //       label: (
    //         <NavLink to="/admin/shift">
    //           <span>Shift</span>
    //         </NavLink>
    //       ),
    //       key: "newShift",
    //       icon: <FileDoneOutlined className="text-dark" />,
    //     },
    //   ],
    // },

    (hasPermission("readAll-shifts") ||
      hasPermission("create-shifts") ||
      hasPermission("readSingle-shifts") ||
      hasPermission("update-shifts") ||
      hasPermission("delete-shifts")) && {
      label: "Shift Management",
      key: "shiftManagement",
      icon: <PiArrowCounterClockwise size={17} />,
      children: [
        hasPermission("readAll-shifts") && {
          label: (
            <NavLink to="/admin/all/shift">
              <span>All Shifts</span>
            </NavLink>
          ),
          key: "allShift",
          icon: <FileSyncOutlined className="text-white" />,
        },
        hasPermission("create-shifts") && {
          label: (
            <NavLink to="/admin/create/shift">
              <span>Create Shift</span>
            </NavLink>
          ),
          key: "createShift",
          icon: <FileAddOutlined className="text-white opacity-80" />,
        },
        {
          label: (
            <NavLink to="/admin/all/swap">
              <span>All Swap Request</span>
            </NavLink>
          ),
          key: "SwapShift",
          icon: <SwapOutlined className="text-white" />,
        },
      ],
    },

    (hasPermission("delete-room") ||
      hasPermission("update-room") ||
      hasPermission("readSingle-room") ||
      hasPermission("readAll-room") ||
      hasPermission("create-room")) && {
      label: "Room Management",
      key: "roomManagement",
      icon: <MdOutlineMeetingRoom size={17} />,
      children: [
        hasPermission("readAll-room") && {
          label: (
            <NavLink to="/all/rooms">
              <span>All Rooms</span>
            </NavLink>
          ),
          key: "allRoom",
          icon: <BankOutlined className="text-white" />,
        },
      ],
    },
    // hasPermission("readAll-employmentStatus") && {
    // 	label: "EMPLOYEMENT",
    // 	key: "employementStatus",
    // 	icon: <RocketOutlined className="text-white" />,
    // 	children: [
    // 		hasPermission("readAll-employmentStatus") && {
    // 			label: (
    // 				<NavLink to='/admin/employment-status'>
    // 					<span>Status</span>
    // 				</NavLink>
    // 			),
    // 			key: "employementStatus",
    // 			icon: <FileDoneOutlined className="text-dark" />,
    // 		},
    // 	],
    // },

    (hasPermission("readAll-publicHoliday") ||
      hasPermission("create-leaveApplication") ||
      hasPermission("readAll-leavePolicy") ||
      hasPermission("readAll-leaveApplication") ||
      hasPermission("readSingle-leaveApplication") ||
      hasPermission("update-leaveApplication") ||
      hasPermission("delete-leaveApplication")) && {
      label: "Leave Management",
      key: "leaveManagement",
      icon: <LuCalendarClock size={17} className="text-white" />,
      children: [
        hasPermission("readAll-leaveApplication") && {
          label: (
            <NavLink to="/admin/leave">
              <span>All Leave Status</span>
            </NavLink>
          ),
          key: "leaveStatus",
          icon: <ContactsOutlined className="text-white" />,
        },
        hasPermission("readAll-leaveApplication") && {
          label: (
            <NavLink to="/admin/leavestats">
              <span>Leave Statistics</span>
            </NavLink>
          ),
          key: "leaveStatistics",
          icon: <DotChartOutlined className="text-white" />,
        },
        hasPermission("readSingle-leaveApplication") && {
          label: (
            <NavLink to={`/admin/leave/user/${user}`}>
              <span>My Leaves</span>
            </NavLink>
          ),
          key: "myLeaves",
          icon: <CarryOutOutlined className="text-white" />,
        },
        hasPermission("readAll-leavePolicy") && {
          label: (
            <NavLink to="/admin/leave-policy">
              <span>Leave Policy</span>
            </NavLink>
          ),
          key: "leavePolicy",
          icon: <ExceptionOutlined className="text-white" />,
        },
        hasPermission("readAll-publicHoliday") && {
          label: (
            <NavLink to="/admin/holiday/public">
              <span>Bank Holiday</span>
            </NavLink>
          ),
          key: "publicHoliday",
          icon: <BankOutlined className="text-white" />,
        },
      ],
    },

    (hasPermission("readSingle-training") ||
      hasPermission("delete-training") ||
      hasPermission("update-training") ||
      hasPermission("create-training") ||
      hasPermission("readAll-training")) && {
      label: "Training ",
      key: "training",
      icon: <FaChalkboardTeacher size={17} className="text-white" />,
      children: [
        {
          label: (
            <NavLink to="/admin/training">
              <span>Training Schedule</span>
            </NavLink>
          ),
          key: "trainingschedule",
          icon: <FileSyncOutlined className="text-white" />,
        },
        {
          label: (
            <NavLink to="/admin/all/training">
              <span>All Training</span>
            </NavLink>
          ),
          key: "alltraining",
          icon: <ReconciliationOutlined className="text-white" />,
        },
      ],
    },

    // (hasPermission("readAll-weeklyHoliday") ||
    //   hasPermission("readAll-publicHoliday")) && {
    //   label: "Holiday",
    //   key: "holiday",
    //   icon: <CalendarOutlined className="text-white" />,
    //   children: [
    //     hasPermission("readAll-weeklyHoliday") && {
    //       label: (
    //         <NavLink to="/admin/holiday/week">
    //           <span>Weekly Holiday</span>
    //         </NavLink>
    //       ),
    //       key: "weeklyHoliday",
    //       icon: <PieChartFilled className="text-dark" />,
    //     },
    //     hasPermission("readAll-publicHoliday") && {
    //       label: (
    //         <NavLink to="/admin/holiday/public">
    //           <span>Bank Holiday</span>
    //         </NavLink>
    //       ),
    //       key: "publicHoliday",
    //       icon: <PieChartFilled className="text-dark" />,
    //     },
    //   ],
    // },

    // hasPermission("readAll-leavePolicy") && {
    //   label: "LEAVE POLICY",
    //   key: "leavePolicy",
    //   icon: <CalendarOutlined className="text-white" />,
    //   children: [
    //     hasPermission("readAll-leavePolicy") && {
    //       label: (
    //         <NavLink to="/admin/leave-policy">
    //           <span>Leave Policy</span>
    //         </NavLink>
    //       ),
    //       key: "leavePolicy",
    //       icon: <PieChartFilled className="text-dark" />,
    //     },
    //   ],
    // },

    hasPermission("readAll-announcement") && {
      label: "Announcement",
      key: "announcement",
      icon: <NotificationFilled className="text-white" />,
      children: [
        hasPermission("readAll-announcement") && {
          label: (
            <NavLink to="/admin/announcement">
              <span>Announcement List</span>
            </NavLink>
          ),
          key: "announcement",
          icon: <FlagFilled className="text-dark" />,
        },
      ],
    },

    // (hasPermission("readAll-account") ||
    // 	hasPermission("readAll-transaction") ||
    // 	hasPermission("create-transaction")) && {
    // 	label: "ACCOUNTS",
    // 	key: "accounts",
    // 	icon: <WalletOutlined className="text-white"/>,
    // 	children: [
    // 		hasPermission("readAll-account") && {
    // 			label: (
    // 				<NavLink to='/admin/account/'>
    // 					<span>Account</span>
    // 				</NavLink>
    // 			),
    // 			key: "accountList",
    // 			icon: <UnorderedListOutlined className="text-dark" />,
    // 		},
    // 		hasPermission("create-transaction") && {
    // 			label: (
    // 				<NavLink to='/admin/transaction/create'>
    // 					<span>New Transaction</span>
    // 				</NavLink>
    // 			),
    // 			key: "newTransaction",
    // 			icon: <CheckOutlined className="text-white" />,
    // 		},
    // 		hasPermission("readAll-transaction") && {
    // 			label: (
    // 				<NavLink to='/admin/transaction/'>
    // 					<span>Transaction List</span>
    // 				</NavLink>
    // 			),
    // 			key: "transactionList",
    // 			icon: <UnorderedListOutlined className="text-white" />,
    // 		},
    // 	],
    // },

    // hasPermission("readAll-account") && {
    // 	label: "FINANCE REPORT",
    // 	key: "report",
    // 	icon: <FlagOutlined className="text-white"/>,
    // 	children: [
    // 		hasPermission("readAll-account") && {
    // 			label: (
    // 				<NavLink to='/admin/account/trial-balance'>
    // 					<span>Trial Balance</span>
    // 				</NavLink>
    // 			),
    // 			key: "trialBalance",
    // 			icon: <FileDoneOutlined className="text-white"/>,
    // 		},
    // 		hasPermission("readAll-account") && {
    // 			label: (
    // 				<NavLink to='/admin/account/balance-sheet'>
    // 					<span>Balance Sheet</span>
    // 				</NavLink>
    // 			),
    // 			key: "balanceSheet",
    // 			icon: <FileOutlined className="text-white" />,
    // 		},
    // 		hasPermission("readAll-account") && {
    // 			label: (
    // 				<NavLink to='/admin/account/income'>
    // 					<span>Income Statement</span>
    // 				</NavLink>
    // 			),
    // 			key: "incomeStatement",
    // 			icon: <FileSyncOutlined className="text-white"/>,
    // 		},
    // 	],
    // },

    // (hasPermission("crate-award") || hasPermission("readAll-award")) && {
    // 	label: "AWARDS",
    // 	key: "award",
    // 	icon: <TrophyFilled />,
    // 	children: [
    // 		hasPermission("create-award") && {
    // 			label: (
    // 				<NavLink to='/admin/award/new'>
    // 					<span>New Award</span>
    // 				</NavLink>
    // 			),
    // 			key: "newAward",
    // 			icon: <TrophyFilled />,
    // 		},

    // 		hasPermission("readAll-award") && {
    // 			label: (
    // 				<NavLink to='/admin/award'>
    // 					<span>Award</span>
    // 				</NavLink>
    // 			),
    // 			key: "award",
    // 			icon: <TrophyFilled />,
    // 		},
    // 	],
    // },

    (hasPermission("create-task") ||
      hasPermission("create-project") ||
      hasPermission("readAll-project") ||
      hasPermission("create-projectTeam") ||
      hasPermission("create-milestone") ||
      hasPermission("readAll-priority") ||
      hasPermission("create-task-Status")) && {
      label: "Task Management",
      key: "task",
      icon: <FaTasks size={17} className="text-white"/>,
      children: [
        hasPermission("create-task") && {
          label: (
            <NavLink to="/admin/add/task">
              <span>Add Task</span>
            </NavLink>
          ),
          key: "addTask",
          icon: <FileAddOutlined className="text-white"/>,
        },
        hasPermission("create-task") && {
          label: (
            <NavLink to="/admin/task">
              <span>All Task</span>
            </NavLink>
          ),
          key: "allTask",
          icon: <SolutionOutlined className="text-white" />,
        },
        // {
        //   label: (
        //     <NavLink to="/admin/project/new">
        //       <span>Add Project</span>
        //     </NavLink>
        //   ),
        //   key: "project",
        //   icon: <SettingOutlined />,
        // },
        // hasPermission("readAll-project") && {
        //   label: (
        //     <NavLink to="/admin/project">
        //       <span>All Project</span>
        //     </NavLink>
        //   ),
        //   key: "allProject",
        //   icon: <SettingOutlined />,
        // },
        // hasPermission("create-projectTeam") && {
        //   label: (
        //     <NavLink to="/admin/team">
        //       <span>Team</span>
        //     </NavLink>
        //   ),
        //   key: "team",
        //   icon: <SettingOutlined />,
        // },
        // (hasPermission("create-priority") ||
        //   hasPermission("readAll-priority")) && {
        //   label: (
        //     <NavLink to="/admin/task-priority">
        //       <span>Task Priority</span>
        //     </NavLink>
        //   ),
        //   key: "taskPriority",
        //   icon: <SettingOutlined />,
        // },
        // hasPermission("create-milestone") && {
        //   label: (
        //     <NavLink to="/admin/milestone">
        //       <span>Add Milestone</span>
        //     </NavLink>
        //   ),
        //   key: "milestone",
        //   icon: <SettingOutlined />,
        // },

        // hasPermission("create-taskStatus") && {
        //   label: (
        //     <NavLink to="/admin/task-status">
        //       <span>Add Task Status</span>
        //     </NavLink>
        //   ),
        //   key: "taskStatus",
        //   icon: <SettingOutlined />,
        // },
      ],
    },
    (hasPermission("readAll-meeting") ||
      hasPermission("create-meeting") ||
      hasPermission("readSingle-meeting") ||
      hasPermission("delete-meeting") ||
      hasPermission("update-meeting")) && {
      label: "Meetings Management",
      key: "meeting",
      icon: <LuScreenShare size={17} className="text-white" />,
      children: [
        hasPermission("create-meeting") && {
          label: (
            <NavLink to="/admin/meeting">
              <span className="!capitalize">schedule meeting</span>
            </NavLink>
          ),
          key: "addmeeting",
          icon: <VideoCameraAddOutlined className="text-white" />,
        },
        hasPermission("readAll-meeting") && {
          label: (
            <NavLink to="/admin/all/meeting">
              <span>All Meetings</span>
            </NavLink>
          ),
          key: "meetinglist",
          icon: <FundProjectionScreenOutlined className="text-white" />,
        },
      ],
    },
    // hasPermission("readAll-setting") && {
    //   label: "SETTINGS",
    //   key: "settings",
    //   icon: <SettingOutlined />,
    //   children: [
    //     hasPermission("readAll-setting") && {
    //       label: (
    //         <NavLink to="/admin/company-setting">
    //           <span>Company Settings</span>
    //         </NavLink>
    //       ),
    //       key: "invoiceSetting",
    //       icon: <SettingOutlined />,
    //     },
    //   ],
    // },
  ];

  return (
    <div>
      <Menu
        style={{
          backgroundColor: 'transparent',
          padding: "0px 4px 3rem 4px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        mode="inline"
        items={menu}
        className="sidenav-menu text-white !border-0"
        // openKeys={[sideNavOpenKeys]}
      />
    </div>
  );
};

export default Sidenav;
