import React from 'react';
import { UserOutlined } from "@ant-design/icons";
import { Row, Col, Card } from "antd";
import { AiOutlineUser } from 'react-icons/ai';


const AttendaceRecord = (data) => {
  return (
    <Row gutter={16}>
      <Col
        // xs={24} sm={12} md={8} lg={6}
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        lg={{ span: 6, offset: 0 }}
        xl={{ span: 6, offset: 0 }}
        md={{ span: 6, offset: 0 }}
      >
        <Card className="text-center ant-shadow rounded-[20px] bg-[#DCFCE7] mb-2 p-0 ">
          <h3 style={{ fontWeight: "bold" }}>Today
            <br />
            Present</h3>
          <div style={{ fontSize: "26px" }}>
            <AiOutlineUser className='bg-[#3AD859] text-white text-[30px] rounded-[50px] p-[5px] inline-block mr-2' />
            <span style={{ fontWeight: "bold" }}>{data?.data?.totalPresent == null ? "-" : data?.data?.totalPresent}</span>
          </div>
        </Card>
      </Col>
      <Col
        // xs={24} sm={12} md={8} lg={6}
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        lg={{ span: 6, offset: 0 }}
        xl={{ span: 6, offset: 0 }}
        md={{ span: 6, offset: 0 }}
      >        
      <Card className="text-center ant-shadow rounded-[20px] bg-[#FFE2E6] mb-2 p-0 ">
          <h3 style={{ fontWeight: "bold" }}>Today
            <br />
            Absent</h3>
          <div style={{ fontSize: "26px" }}>
            <AiOutlineUser className='bg-[#F65C7D] text-white text-[30px] rounded-[50px] p-[5px] inline-block mr-2' />
            <span style={{ fontWeight: "bold" }}>{data?.data?.totalAbsent == null ? "-" : data?.data?.totalAbsent}</span>
          </div>
        </Card>
      </Col>
      <Col
        // xs={24} sm={12} md={8} lg={6}
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        lg={{ span: 6, offset: 0 }}
        xl={{ span: 6, offset: 0 }}
        md={{ span: 6, offset: 0 }}
      >        
      <Card className="text-center ant-shadow rounded-[20px] bg-[#e9d1fd] mb-2 p-0 ">
          <h3 style={{ fontWeight: "bold" }}>Today
            <br />
            Leaves</h3>
          <div style={{ fontSize: "26px" }}>
            <AiOutlineUser className='bg-[#BC80FF] text-white text-[30px] rounded-[50px] p-[5px] inline-block mr-2' />
            <span style={{ fontWeight: "bold" }}>{data?.data?.totalLeaves == null ? "-" : data?.data?.totalLeaves}</span>
          </div>
        </Card>
      </Col>
      <Col
        // xs={24} sm={12} md={8} lg={6}
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        lg={{ span: 6, offset: 0 }}
        xl={{ span: 6, offset: 0 }}
        md={{ span: 6, offset: 0 }}
      >       
       <Card className="text-center ant-shadow rounded-[20px] bg-[#FDD2C6] mb-2 p-0 ">
          <h3 style={{ fontWeight: "bold" }}>Today
            <br />
            Holidays</h3>
          <div style={{ fontSize: "26px" }}>
            <AiOutlineUser className='bg-[#FD5D3B] text-white text-[30px] rounded-[50px] p-[5px] inline-block mr-2' />
            <span style={{ fontWeight: "bold" }}>{data?.data?.totalHoliday == null ? "-" : data?.data?.totalHoliday}</span>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default AttendaceRecord;
