import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
	addDesignation,
	loadAllDesignation,
} from "../../redux/rtk/features/designation/designationSlice";
import UploadMany from "../Card/UploadMany";
import styles from "./AddDesignation.module.css";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const AddDesignation = ({onCancel}) => {
	const dispatch = useDispatch();
	const { Title } = Typography;

	const [loader, setLoader] = useState(false);
	const onClickLoading = () => {
		setLoader(true);
	};

	console.log(onCancel,'onCancel')
	const [form] = Form.useForm();

	const onFinish = async (values) => {
		try {
			const resp = await dispatch(addDesignation(values));
			if (resp.payload.message === "success") {
				setLoader(false);
				dispatch(loadAllDesignation());
				form.resetFields();
				onCancel(false)
			}
		} catch (error) {
			setLoader(false);
			console.log(error.message);
		}
	};

	const onFinishFailed = (errorInfo) => {
		setLoader(false);
		console.log("Failed:", errorInfo);
	};

	return (
    <Fragment>
      <UserPrivateComponent permission={"create-designation"}>
        <Row justify="space-between" gutter={[0, 30]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 16, offset: 4 }}
            md={{ span: 16, offset: 4 }}
          >
            <Card bordered={false}>
              <Form
                form={form}
                name="basic"
                // labelCol={{
                // 	span: 8,
                // }}
                // wrapperCol={{
                // 	span: 16,
                // }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  style={{ marginBottom: "30px" }}
                  label="Designation Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input designation name!",
                    },
                  ]}
                >
                  <Input
                    className="border-none ant-shadow"
                    placeholder="Enter Designation Name!"
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "20px" }}
                  // wrapperCol={{
                  // 	offset: 6,
                  // 	span: 12,
                  // }}
                >
                  <Button
                    onClick={onClickLoading}
                    type="primary"
                    block
                    htmlType="submit"
                    shape="round"
                    size="large"
                    loading={loader}
                  >
                    Add designation
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddDesignation;
