import { Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ViewBtn = ({ path }) => {
	return (
		<div>
			<Tooltip title='View'>
				<Link to={path}>
					<button className='text-[#23B9F8] text-lg font-bold px-2 mr-2'>
						<i className='bi bi-eye-fill'></i>
					</button>
				</Link>
			</Tooltip>
		</div>
	);
};

export default ViewBtn;
