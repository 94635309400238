import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import { useDispatch, useSelector } from "react-redux";
import { deleteDesignation } from "../../redux/rtk/features/designation/designationSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

const DeleteDesignation = ({ id }) => {
	const [deldesig, setDelDesig] = useState(false);
	
	return (
		<>
		<button type='primary' onClick={()=>setDelDesig(true)} className='mr-3 '>
			<MdDelete name='Delete' size={30} style={{color:"#EB131C"}} />
		</button>
		<DelModal
        open={deldesig}
        onClose={setDelDesig}
        designId={id}
        content={"Are you sure you want to delete ?"}
        type={"Designdel"}
      />
		</>
	);
};
export default DeleteDesignation;
