import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const LeaveLineChart = ({ dataLine }) => {
    return (
        <ResponsiveContainer width="100%" height={140}>
            <LineChart

                data={dataLine}
                margin={{
                    top: 5,
                    right: 25,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Accepted" stroke="#4CF552" />
                <Line type="monotone" dataKey="Rejected" stroke="#EB131C" />
                <Line type="monotone" dataKey="Pending" stroke="#0095DE" />

            </LineChart>
        </ResponsiveContainer>
    );
};

export default LeaveLineChart;
