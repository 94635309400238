import tw from "tailwind-styled-components";
const BlueBackground = tw.div`
  bg-blue-500
`;
export const CsvLinkBtn = tw(BlueBackground)`
"bg-blue-500
hover:bg-blue-700
text-white
  font-bold 
  py-1 
  px-3 
  rounded 
  mr-2
  ml-2
  mt-4
  mb-4
`;

export const PrintAllBtn = tw(BlueBackground)`
  hover:bg-blue-700 
  text-white
  font-bold 
  py-1 
  px-3 
  rounded 
  mr-2
  ml-2
  mt-3
`;

export const TableHeraderh2 = tw.h2`

text-2xl
ml-4
`;
