import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Select, Card, DatePicker, TimePicker } from "antd";
import { toast } from "react-toastify";
import moment from 'moment';
import {
    addManualAttendance,
    loadAllAttendance,
} from "../../redux/rtk/features/attendance/attendanceSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
const { Option } = Select;

const MarkAttendance = ({ onSearch, onCancel }) => {
    const AllEmp = useSelector((state) => state.users.list);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(loadAllStaff());
    }, []);

    const onFinish = async (values) => {
        values["employeeId"] = values.employeeId;
        const FormData = {
            ...values,
        };
        setLoader(true);
        const resp = await dispatch(addManualAttendance(FormData));
        console.log(resp, "resp");
        if (resp.payload.message === "success") {
            setLoader(false);
            onCancel(false);
            form.resetFields();
            /*  setInTimeDate({});
                 setOutTimeDate({}); */
            dispatch(loadAllAttendance(`query=all&page=${1}&count=${10}`));
        } else {
            setLoader(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        toast.warning("Failed at adding shift");
        setLoader(false);
    };
    function AttendanceModalChange(value) { }
    function onSearchModal(val) { }

    return (
        <>
            <div className="p-4 text-center">
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "#000" }}>Mark Attendance</h2>
            </div>
            <div>
                <Form
                    layout="vertical"
                    form={form}
                    labelCol={{
                        span: 16,
                        offset: 4
                    }}
                    wrapperCol={{
                        span: 16,
                        offset: 4

                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div>
                        <Form.Item
                            label="Date:"
                            name="date"
                            labelAlign="top"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the date!",
                                },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 2px 5px #d1cccc"
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Employee Name:"
                            name="employeeId"
                            labelAlign="top"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the employee ID!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: "100%",
                                    boxShadow: "0px 0px 5px gray",
                                    borderRadius: "5px",
                                }}
                                placeholder="Select a Name"
                                optionFilterProp="children"
                                onChange={AttendanceModalChange}
                                onSearch={onSearchModal}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {AllEmp &&
                                    AllEmp.map((emp) => (
                                        <Select.Option key={emp.id} value={emp?.employeeId}>
                                            {emp?.firstName + " " + emp.lastName}
                                        </Select.Option>
                                    ))}
                            </Select>

                            {/* <Input
                                style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 2px 5px #d1cccc"
                                }}
                                type="text"
                                placeholder="Enter Employee ID"
                            /> */}
                        </Form.Item>

                        <Form.Item
                            label="Attendance Status:"
                            name="attendenceStatus"
                            labelAlign="top"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select attendance status!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 2px 5px #d1cccc"
                                }}
                                className="mb-3"
                                placeholder="Select Attendance"
                            >
                                <Option value="present">Present</Option>
                                <Option value="absent">Absent</Option>
                                {/* <Option value="leave">Leave</Option> */}
                                <Option value="holiday">Holiday</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Check-In Time"
                            name="checkinTime"
                            labelAlign="top"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the check-in time!",
                                },
                            ]}
                        >
                            <TimePicker
                                style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 2px 5px #d1cccc"
                                }}
                                className="date-picker hr-staffs-date-picker"
                                format="HH:mm"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Check-Out Time"
                            name="checkoutTime"
                            labelAlign="top"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the check-out time!",
                                },
                            ]}
                        >
                            <TimePicker
                                style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 2px 5px #d1cccc"
                                }}
                                className="date-picker hr-staffs-date-picker"
                                format="HH:mm"
                            />
                        </Form.Item>



                    </div>

                    <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                        <Button
                            className="mb-3"
                            type="primary"
                            size="medium"
                            htmlType="submit" block>
                            Mark Attendance
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
};

export default MarkAttendance;
