import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  DatePicker,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import { MdContentCopy } from "react-icons/md";

import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import moment from "moment";
import { getISOWeek } from "date-fns";
import {
  loadAllLocation,
  loadSingleLocation,
  resetLocation,
} from "../../redux/rtk/features/location/locationSlice";
import { loadRoomsByLocationId } from "../../redux/rtk/features/rooms/roomSlice";
import { CreateShiftManagement } from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import { AiOutlineEdit } from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";
import styles from "../leave/leave.module.css";
import tr from "date-fns/esm/locale/tr/index.js";

function CustomTable({ list, drawer }) {
  const user_id = localStorage.getItem("id");
  const location = useSelector((state) => state.location.list);
  const RoomsList = useSelector((state) => state.Rooms.loadRoomsid.data);
  const { RangePicker } = DatePicker;
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [shift, setShift] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [shiftUser, setShiftUser] = useState();
  const [sChange, setSChange] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(null);
  const [locationId, setLocationId] = useState();
  const [form] = Form.useForm();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const Today_date = moment(new Date()).format('YYYY-MM-DD')
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // Monday shift
  const [mStatus, setMStatus] = useState(false);
  const [mRoom, setMRoom] = useState("");
  const [mStartTime, setMStartTime] = useState("");
  const [mEndTime, setMEndTime] = useState("");
  const [mBreakTime, setMBreakTime] = useState("");
  const [mFolderTime, setMFolderTime] = useState("");

  // Tuesday shift
  const [tStatus, setTStatus] = useState(false);
  const [tRoom, setTRoom] = useState("");
  const [tStartTime, setTStartTime] = useState("");
  const [tEndTime, setTEndTime] = useState("");
  const [tBreakTime, setTBreakTime] = useState("");
  const [tFolderTime, setTFolderTime] = useState("");

  // Wednesday shift
  const [wStatus, setWStatus] = useState(false);
  const [wRoom, setWRoom] = useState("");
  const [wStartTime, setWStartTime] = useState('');
  const [wEndTime, setWEndTime] = useState("");
  const [wBreakTime, setWBreakTime] = useState("");
  const [wFolderTime, setWFolderTime] = useState("");

  // Thursday shift
  const [thStatus, setTHStatus] = useState(false);
  const [thRoom, setTHRoom] = useState("");
  const [thStartTime, setTHStartTime] = useState("");
  const [thEndTime, setTHEndTime] = useState("");
  const [thBreakTime, setTHBreakTime] = useState("");
  const [thFolderTime, setTHFolderTime] = useState("");

  // Friday shift
  const [fStatus, setFStatus] = useState(false);
  const [fRoom, setFRoom] = useState("");
  const [fStartTime, setFStartTime] = useState("");
  const [fEndTime, setFEndTime] = useState("");
  const [fBreakTime, setFBreakTime] = useState("");
  const [fFolderTime, setFFolderTime] = useState("");

  // Saturday shift
  const [saStatus, setSAStatus] = useState(false);
  const [saRoom, setSARoom] = useState("");
  const [saStartTime, setSAStartTime] = useState("");
  const [saEndTime, setSAEndTime] = useState("");
  const [saBreakTime, setSABeakTime] = useState("");
  const [saFolderTime, setSAFolderTime] = useState("");

  // Sunday shift
  const [suStatus, setSUStatus] = useState(false);
  const [suRoom, setSURoom] = useState("");
  const [suStartTime, setSUStartTime] = useState("");
  const [suEndTime, setSUEndTime] = useState("");
  const [suBreakTime, setSUBeakTime] = useState("");
  const [suFolderTime, setSUFolderTime] = useState("");

  useEffect(() => {
    dispatch(loadAllLocation({ status: "true" }));
  }, []);

  // Modal opening effect
  useEffect(() => {
    if (shift) {
      // Reset modal-related state here
      form.resetFields();
      setMStatus(false);
      setMRoom("");
      setMStartTime("");
      setMEndTime("");
      setMBreakTime("");
      setMFolderTime("");

      setTStatus(false);
      setTRoom("");
      setTStartTime("");
      setTEndTime("");
      setTBreakTime("");
      setTFolderTime("");

      setWStatus(false);
      setWRoom("");
      setWStartTime("");
      setWEndTime("");
      setWBreakTime("");
      setWFolderTime("");

      setTHStatus(false);
      setTHRoom("");
      setTHStartTime("");
      setTHEndTime("");
      setTHBreakTime("");
      setTHFolderTime("");

      setFStatus(false);
      setFRoom("");
      setFStartTime("");
      setFEndTime("");
      setFBreakTime("");
      setFFolderTime("");

      setSAStatus(false);
      setSARoom("");
      setSAStartTime("");
      setSAEndTime("");
      setSABeakTime("");
      setSAFolderTime("");

      setSUStatus(false);
      setSURoom("");
      setSUStartTime("");
      setSUEndTime("");
      setSUBeakTime("");
      setSUFolderTime("");

      // Reset general info
      setGeneralInfo(null);
    }
  }, [shift]);


  const dateFormat = "YYYY/MM/DD";

  const columns = [
    {
      id: 1,
      title: "Emp id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      id: 2,
      title: "Employee Name",
      dataIndex: ["firstName", "lastName"],
      key: "firstName",
      render: (text, record) =>
        record?.firstName == null
          ? "--"
          : record?.firstName + " " + record.lastName,
    },

    {
      id: 3,
      title: "Designation",
      dataIndex: "designationHistory",
      key: "designationHistory",
      render: (designationHistory) =>
        designationHistory[0]?.designation?.name == null
          ? "--"
          : designationHistory[0]?.designation?.name,
    },
    {
      id: 5,
      title: "Add Shift Details",
      dataIndex: ["firstName", "lastName", "id"],
      key: "id",
      render: (text, record) => (
        <>
          <div>
            <button
              onClick={(e) => handleShiftChange(record)}
              type="primary"
              className="text-[16px] font-[600] text-[#23B9F8]"
            >
              <AiOutlineEdit size={27} className="text-[#23B9F8]" />
            </button>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const handelRangePicker = (values) => {
    setStartDate(values[0].format("YYYY-MM-DD"));
    setEndDate(values[1].format("YYYY-MM-DD"));
  };
  const endWeekNumber = getISOWeek(new Date(endDate));
  const currentYear = new Date(endDate).getFullYear();

  const getWeekDatesAndDayNames = (weekNumber, year) => {
    let result = [];
    const firstDayOfYear = new Date(year, 0, 1);
    const daysToFirstDayOfWeek = 1 - firstDayOfYear.getDay();
    const firstDayOfWeek = new Date(
      year,
      0,
      1 + daysToFirstDayOfWeek + (weekNumber - 1) * 7
    );

    for (let i = 0; i < 7; i++) {
      let currentDate = new Date(
        firstDayOfWeek.getFullYear(),
        firstDayOfWeek.getMonth(),
        firstDayOfWeek.getDate() + i
      );
      let dayNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let dayName = dayNames[currentDate.getDay()];
      result.push({
        date: dayjs(
          currentDate.toLocaleDateString("en-US", {
            timeZone: "Asia/Karachi",
          })
        ).format("YYYY-MM-DD"),
        dayName: dayName,
      });
    }

    return result;
  }

  const [weekArray, setweekArray] = useState([]);

  useEffect(() => {
    if (endWeekNumber && currentYear) {
      const data = getWeekDatesAndDayNames(endWeekNumber, currentYear);
      setweekArray(data);
    }
  }, [endWeekNumber])


  const handleLocationChange = (id, value) => {
    setLocationId(id);
    setSelectedLocation(value);
    dispatch(loadSingleLocation(id));
    dispatch(loadRoomsByLocationId(id));
  };

  const SubmitCreateShift = async () => {
    if (
      mStatus ||
      tStatus ||
      wStatus ||
      thStatus ||
      fStatus ||
      saStatus ||
      suStatus
    ) {
      if (mStatus) {
        if (mRoom == "") {
          return toast.error("Please select Monday shift Room!");
        }
        if (mStartTime == "") {
          return toast.error("Please set your Monday Start Time.");
        }
        if (mEndTime == "") {
          return toast.error("Please set your Monday End Time.");
        }
        if (mStartTime > mEndTime) {
          return toast.error(
            "your Monday Shift End time is less then Start Time"
          );
        }
        if (mBreakTime == "") {
          return toast.error("Please set your Monday Break Time.");
        }
      }
      if (tStatus) {
        if (tRoom == "") {
          return toast.error("Please select Tuesday shift Room!");
        }
        if (tStartTime == "") {
          return toast.error("Please set your Tuesday Start Time.");
        }
        if (tEndTime == "") {
          return toast.error("Please set your Tuesday End Time.");
        }
        if (tStartTime > tEndTime) {
          return toast.error(
            "your Tuesday Shift End time is less then Start Time"
          );
        }
        if (tBreakTime == "") {
          return toast.error("Please set your Tuesday Break Time.");
        }
      }
      if (wStatus) {
        if (wRoom == "") {
          return toast.error("Please select Wednesday shift Room!");
        }
        if (wStartTime == "") {
          return toast.error("Please set your Wednesday Start Time.");
        }
        if (wEndTime == "") {
          return toast.error("Please set your Wednesday End Time.");
        }
        if (wStartTime > wEndTime) {
          return toast.error(
            "your Wednesday Shift End time is less then Start Time"
          );
        }
        if (wBreakTime == "") {
          return toast.error("Please set your Wednesday Break Time.");
        }
      }
      if (thStatus) {
        if (thRoom == "") {
          return toast.error("Please select Thursday shift Room!");
        }
        if (thStartTime == "") {
          return toast.error("Please set your Thursday Start Time.");
        }
        if (thEndTime == "") {
          return toast.error("Please set your Thursday End Time.");
        }
        if (thStartTime > thEndTime) {
          return toast.error(
            "your Thursday Shift End time is less then Start Time"
          );
        }
        if (thBreakTime == "") {
          return toast.error("Please set your Thursday Break Time.");
        }
      }
      if (fStatus) {
        if (fRoom == "") {
          return toast.error("Please select Friday shift Room!");
        }
        if (fStartTime == "") {
          return toast.error("Please set your Friday Start Time.");
        }
        if (fEndTime == "") {
          return toast.error("Please set your Friday End Time.");
        }
        if (fStartTime > fEndTime) {
          return toast.error(
            "your Friday Shift End time is less then Start Time"
          );
        }
        if (fBreakTime == "") {
          return toast.error("Please set your Friday Break Time.");
        }
      }
      if (saStatus) {
        if (saRoom == "") {
          return toast.error("Please select Saturday shift Room!");
        }
        if (saStartTime == "") {
          return toast.error("Please set your Saturday Start Time.");
        }
        if (saEndTime == "") {
          return toast.error("Please set your Saturday End Time.");
        }
        if (saStartTime > saEndTime) {
          return toast.error(
            "your Saturday Shift End time is less then Start Time"
          );
        }
        if (saBreakTime == "") {
          return toast.error("Please set your Saturday Break Time.");
        }
      }
      if (suStatus) {
        if (suRoom == "") {
          return toast.error("Please select Sunday shift Room!");
        }
        if (suStartTime == "") {
          return toast.error("Please set your Sunday Start Time.");
        }
        if (suEndTime == "") {
          return toast.error("Please set your Sunday End Time.");
        }
        if (suStartTime > suEndTime) {
          return toast.error(
            "your Sunday Shift End time is less then Start Time"
          );
        }
        if (suBreakTime == "") {
          return toast.error("Please set your Sunday Break Time.");
        }
      }
    } else {
      return toast.error("Please set at least one day Shift");
    }
    const values = {
      shiftFrom: startDate,
      shiftTo: endDate,
      weekNumber: endWeekNumber,
      // userId: shiftUser?.id,
      userId: selectedRowKeys.length > 0 ? selectedRowKeys : [shiftUser?.id],
      locationId: locationId,
      assignedBy: user_id,
      generalInfo: generalInfo,
      schedule: [
        {
          day: weekArray[0]?.dayName,
          shiftDate: weekArray[0]?.date,
          startTime: mStartTime,
          endTime: mEndTime,
          breakTime: mBreakTime,
          folderTime: mFolderTime,
          roomId: mRoom,
          status: mStatus,
        },
        {
          day: weekArray[1]?.dayName,
          shiftDate: weekArray[1]?.date,
          startTime: tStartTime,
          endTime: tEndTime,
          breakTime: tBreakTime,
          folderTime: tFolderTime,
          roomId: tRoom,
          status: tStatus,
        },
        {
          day: weekArray[2]?.dayName,
          shiftDate: weekArray[2]?.date,
          startTime: wStartTime,
          endTime: wEndTime,
          breakTime: wBreakTime,
          folderTime: wFolderTime,
          roomId: wRoom,
          status: wStatus,
        },
        {
          day: weekArray[3]?.dayName,
          shiftDate: weekArray[3]?.date,
          startTime: thStartTime,
          endTime: thEndTime,
          breakTime: thBreakTime,
          folderTime: thFolderTime,
          roomId: thRoom,
          status: thStatus,
        },
        {
          day: weekArray[4]?.dayName,
          shiftDate: weekArray[4]?.date,
          startTime: fStartTime,
          endTime: fEndTime,
          breakTime: fBreakTime,
          folderTime: fFolderTime,
          roomId: fRoom,
          status: fStatus,
        },
        {
          day: weekArray[5]?.dayName,
          shiftDate: weekArray[5]?.date,
          startTime: saStartTime,
          endTime: saEndTime,
          breakTime: saBreakTime,
          folderTime: saFolderTime,
          roomId: saRoom,
          status: saStatus,
        },
        {
          day: weekArray[6]?.dayName,
          shiftDate: weekArray[6]?.date,
          startTime: suStartTime,
          endTime: suEndTime,
          breakTime: suBreakTime,
          folderTime: suFolderTime,
          roomId: suRoom,
          status: suStatus,
        },
      ],
    };

    const resp = await dispatch(CreateShiftManagement(values));
    if (resp.payload.message === "success") {
      form.resetFields();
      dispatch(resetLocation());
      setSelectedLocation(null);
      setSChange(false)
      setLoader(false);
      setShift(false);
    } else {
      setLoader(false);
    }
  };

  const handleCopy = (value) => {
    const formattedTime = dayjs(value).format("h:mm:ss A"); // Format your time as needed

    navigator.clipboard.writeText(formattedTime).then(
      () => {
        toast.success("Copied");
      },
      (err) => {
        toast.error("Unable to copy to clipboard");
      }
    );
  };


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function handleShiftChange(values) {
    setShiftUser(values);
    setShift(true);
  }

  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        Create Shift
      </h5>
      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Week Date Range</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0 mr-0">
          <div className="ant-card-body">
            <RangePicker
              defaultValue={[moment(new Date()), moment(new Date())]}
              format={dateFormat}
              onChange={handelRangePicker}
              className="mb-3"
            />
            <h5 className="text-[16px] font-[600] text-gray-400">Week</h5>
            <Input
              className="bg-gray-200"
              value={"WEEK" + "  " + endWeekNumber}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Choose Location</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0 mr-0">
          <div className="ant-card-body">
            <Form.Item className="!mb-0">
              <Select
                className="mr-2"
                placeholder="Select Location"
                loading={location?.length === 0}
                onChange={handleLocationChange}
                value={selectedLocation}
              >
                {location &&
                  location.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.locationName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>

      <Card bordered={false} className="ant-shadow rounded-[10px] p-0 mx-2">
        <Table
          rowSelection={rowSelection}
          scroll={{ x: true }}
          loading={!list}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <Modal
        centered
        open={shift}
        onOk={() => setShift(false)}
        onCancel={() => setShift(false)}
        footer={null}
        width={1000}
        className="shift-modal-custom mt-[30px] mb-[40px]"
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
              borderBottom: "unset !important",
            }}
          >
            Add Shift Details
          </div>
        }
      >
        <UserPrivateComponent permission={"create-shift"}>
          <div className="text-center mb-4 flex items-center justify-between flex-wrap">
            <div style={{ visibility: sChange ? "visible" : "hidden" }}>
              <Button
                onClick={() => setSChange(false)}
                className="p-4 flex items-center text-[16px] font-[500] hover:text-[#0723a1] bg-[#eef1f8] border-transparent"
              >
                <BsArrowLeft />
                &nbsp; Previous
              </Button>
            </div>
            <h2 className="text-[18px] font-[600]">
              Shift For Staff:&nbsp;
              {selectedRowKeys.length > 0 ? 
              <span className="font-[500]">
              Multiple Staff
            </span>
              :
              <span className="font-[500]">
                {shiftUser?.firstName + " " + shiftUser?.lastName}
              </span>
}
            </h2>
            <div style={{ visibility: sChange ? "hidden" : "visible" }}>
              <Button
                onClick={() => setSChange(true)}
                className="py-2 flex items-center text-[16px] font-[500] hover:text-[#0723a1] bg-[#eef1f8] border-transparent"
              >
                Next&nbsp;
                <BsArrowRight />
              </Button>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <Form
              form={form}
              onFinish={SubmitCreateShift}
            // fields={[{ name: "throoms", value: thRoom }]}
            >
              <div className={sChange == true ? "hidden" : "block"}>
                <table className="userShift-table">
                  <thead>
                    <tr>
                      <th>Days</th>
                      <th style={{ width: "15%" }}>Date</th>
                      <th>Room</th>
                      <th style={{ width: "17%" }}>Start time</th>
                      <th style={{ width: "17%" }}>End time</th>
                      <th style={{ width: "15%" }}>Break time&nbsp;(min)</th>
                      <th style={{ width: "15%" }}>Folder time&nbsp;(min)</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <Form.Item
                          name={"dayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >

                          <Checkbox
                            disabled={weekArray[0]?.date >= Today_date ? false : true}
                            checked={mStatus}
                            onChange={(e) => setMStatus(e.target.checked)}
                          >
                            {weekArray[0]?.dayName}
                          </Checkbox>

                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"weekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[0]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name="rooms"
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            disabled={mStatus == false ? "disabled" : ""}
                            onChange={(value) => setMRoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name="startTime"
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flex items-center">
                            <TimePicker
                              disabled={mStatus == false ? "disabled" : ""}
                              onChange={(time) =>
                                setMStartTime(
                                  time == null || time == ""
                                    ? new Date()
                                    : dayjs(time).format()
                                )
                              }
                            />
                            {/* <Button
                              onClick={() => handleCopy(mStartTime)}
                              className="border-0 btn !px-[8px]"
                            >
                              <MdContentCopy size={20} />
                            </Button> */}
                          </div>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name="endTime"
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flex items-center">
                            <TimePicker
                              disabled={mStatus == false ? "disabled" : ""}
                              onChange={(time) =>
                                setMEndTime(
                                  time == null || time == ""
                                    ? new Date()
                                    : dayjs(time).format()
                                )
                              }
                            />
                            {/* <Button
                              onClick={() => handleCopy(mEndTime)}
                              className="border-0 btn !px-[8px]"
                            >
                              <MdContentCopy size={20} />
                            </Button> */}
                          </div>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name="breakTime"
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setMBreakTime(value)}
                            disabled={mStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name="folderTime"
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setMFolderTime(value)}
                            disabled={mStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"tdayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[1]?.date >= Today_date ? false : true}
                            checked={tStatus}
                            onChange={(e) => setTStatus(e.target.checked)}
                          >
                            {weekArray[1]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"tweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[1]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"trooms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={tStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setTRoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"tstartTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flex items-center">
                            <TimePicker
                              disabled={tStatus == false ? "disabled" : ""}
                              onChange={(time) =>
                                setTStartTime(
                                  time == null || time == ""
                                    ? new Date()
                                    : dayjs(time).format()
                                )
                              }
                            />
                            {/* <Button
                              onClick={() => handleCopy(tStartTime)}
                              className="border-0 btn !px-[8px]"
                            >
                              <MdContentCopy size={20} />
                            </Button> */}
                          </div>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"tEndTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flex items-center">
                            <TimePicker
                              disabled={tStatus == false ? "disabled" : ""}
                              onChange={(time) =>
                                setTEndTime(
                                  time == null || time == ""
                                    ? new Date()
                                    : dayjs(time).format()
                                )
                              }
                            />
                            {/* <Button
                              onClick={() => handleCopy(tEndTime)}
                              className="border-0 btn !px-[8px]"
                            >
                              <MdContentCopy size={20} />
                            </Button> */}
                          </div>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"tBreakTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setTBreakTime(value)}
                            disabled={tStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"tfolderTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setTFolderTime(value)}
                            disabled={tStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"wdayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[2]?.date >= Today_date ? false : true}
                            checked={wStatus}
                            onChange={(e) => setWStatus(e.target.checked)}
                          >
                            {weekArray[2]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"wweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[2]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"wrooms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={wStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setWRoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"wstarttime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={wStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setWStartTime(dayjs(time).format())
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"wendtime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={wStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setWEndTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"wbreakTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setWBreakTime(value)}
                            disabled={wStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"wfolderTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber

                            onChange={(value) => setWFolderTime(value)}
                            disabled={wStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"thdayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[3]?.date >= Today_date ? false : true}
                            checked={thStatus}
                            onChange={(e) => setTHStatus(e.target.checked)}
                          >
                            {weekArray[3]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"thweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[3]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"throoms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={thStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setTHRoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"thStartTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={thStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setTHStartTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"thEndTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={thStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setTHEndTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"thBreakTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setTHBreakTime(value)}
                            disabled={thStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"thfolderTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setTHFolderTime(value)}
                            disabled={thStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"fdayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[4]?.date >= Today_date ? false : true}
                            checked={fStatus}
                            onChange={(e) => setFStatus(e.target.checked)}
                          >
                            {weekArray[4]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"fweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[4]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"frooms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={fStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setFRoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"fstartTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={fStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setFStartTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"fEndTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={fStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setFEndTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"fBreakTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setFBreakTime(value)}
                            disabled={fStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"fFolderTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setFFolderTime(value)}
                            disabled={fStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"sadayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[5]?.date >= Today_date ? false : true}
                            checked={saStatus}
                            onChange={(e) => setSAStatus(e.target.checked)}
                          >
                            {weekArray[5]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"saweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[5]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"sarooms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={saStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setSARoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"saStartTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={saStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setSAStartTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"saEndTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={saStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setSAEndTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"saBreakTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setSABeakTime(value)}
                            disabled={saStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"saFolderTime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setSAFolderTime(value)}
                            disabled={saStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                          name={"sudayName"}
                          valuePropName="checked"
                          style={{ marginBottom: "10px" }}
                        >
                          <Checkbox
                            disabled={weekArray[6]?.date >= Today_date ? false : true}
                            checked={suStatus}
                            onChange={(e) => setSUStatus(e.target.checked)}
                          >
                            {weekArray[6]?.dayName}
                          </Checkbox>
                        </Form.Item>
                      </td>
                      <td>
                        <div
                          // name={"suweekdate"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Input value={weekArray[6]?.date} disabled />
                        </div>
                      </td>
                      <td>
                        <Form.Item
                          name={"surooms"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            disabled={suStatus == false ? "disabled" : ""}
                            placeholder="Select Room"
                            loading={RoomsList?.length == 0}
                            className="w-[130px]"
                            onChange={(value) => setSURoom(value)}
                          >
                            {RoomsList &&
                              RoomsList?.map((rom) => (
                                <Select.Option key={rom.id} value={rom.id}>
                                  {rom?.roomName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"sustarttime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={suStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setSUStartTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"suEndtime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            disabled={suStatus == false ? "disabled" : ""}
                            onChange={(time) =>
                              setSUEndTime(
                                time == null || time == ""
                                  ? new Date()
                                  : dayjs(time).format()
                              )
                            }
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"suBreaktime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setSUBeakTime(value)}
                            disabled={suStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={"suFoldertime"}
                          style={{ marginBottom: "10px" }}
                        >
                          <InputNumber
                            onChange={(value) => setSUFolderTime(value)}
                            disabled={suStatus == false ? "disabled" : ""}
                            className="w-full h-[34px] !py-[1px]"
                            placeholder="00:00 min"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={sChange == false ? "hidden" : "block"}>
                <p>General information</p>
                <Form.Item name={"generalinfo"}>
                  <TextArea
                    value={generalInfo}
                    onChange={(e) => setGeneralInfo(e.target.value)}
                    placeholder="General information...."
                    showCount
                    rows={7}
                    className={`${styles.CommentBox}`}
                  />
                </Form.Item>
                <div className="flex justify-end items-center gap-4 mt-6">
                  <Form.Item>
                    <button
                      type="submit"
                      htmlType="submit"
                      className="py-2 px-4 text-[16px] font-[500] text-white rounded-[15px]  bg-[#E42976] text-white border-0"
                    >
                      Save
                    </button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const CreateShift = ({ drawer }) => {
  const dispatch = useDispatch()
  const single_user_location = useSelector((state) => state.location.location);
  const userList =
    single_user_location?.user == null ? [] : single_user_location?.user;
  useEffect(() => {
    dispatch(resetLocation());
  }, [])
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={userList} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default CreateShift;
