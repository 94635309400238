import { Button, Card, Col, Form, Input, Row, Table, Typography } from "antd";
import styles from "./styles.module.css"

import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { addRole, getRoles } from "./roleApis";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import AddRolePopup from "../UI/PopUp/AddRolePopup";
function CustomTable({ list ,onAddRole}) {
	const [columnsToShow, setColumnsToShow] = useState([]);

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			id: 2,
			title: "Role Name",
			dataIndex: "name",
			key: "name",
		},
		{
			id: 3,
			title: "Full Name",
			dataIndex: "name",
			key: "fullRoleName",
			render: (text) => getRoleFullName(text) // Use the mapping function on the 'name' field
		},

		{
			id: 3,
			title: "Created At",
			dataIndex: "createdAt",
			key: "addrcreatedAtess",
			render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
		},
		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => (
				<>
					<UserPrivateComponent permission={"readSingle-role"}>
						<ViewBtn path={`/admin/role/${id}/`} />
					</UserPrivateComponent>
				</>
			),
		},
	];

	const [modalVisible, setModalVisible] = useState(false);

	const getRoleFullName = (abbreviation) => {
		const roleMap = {
			'ASC': 'Assistant Coach',
			'NAC': 'Nursery Area Coach',
			'NC': 'Nursery Coach',
			'DOO': 'Director of Operations',
			'CEO': 'Chief Executive Officer'
		};
		return roleMap[abbreviation] || abbreviation; // Return the abbreviation as is if it's not in the map
	};
	const handleOpenModal = () => {
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	const handleAddRole = async (addData) => {
		try {
		  // Handle the attendance search data here
		  console.log(addData);
	  
		  // Call the onAddRole prop and pass the new role data
		  onAddRole(addData);
	  
		  // Close the modal after handling
		  handleCloseModal();
		} catch (error) {
		  console.error("Error handling new role:", error);
		}
	  };
	  
	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	return (
    <>
      <div>
        <h4 className="text-[24px] font-[600] mb-2">Role & Permissions</h4>
      </div>
      <div className="text-center my-2 flex justify-start items-center">
        {list && (
          <div className={`${styles.Button}  items-center`}>
            <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] px-5">
              <CSVLink
                data={list}
                className="mx-3 text-white hover:!text-white text-[15px] font-[400]"
                filename="roles"
              >
                CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}
        {/* <div className={`${styles.Button} text-center m-4 flex items-center`}>
					<AddRolePopup drawer={true} onAddRole={handleAddRole} />
				</div> */}
      </div>
      <Card className={styles.cardBody}>
        <Table
          className={styles.customTable}
          scroll={{ x: true }}
          loading={!list}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>
    </>
  );
}

const AddRole = ({ drawer }) => {

	const [list, setList] = useState(null);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		getRoles()
			.then((d) => setList(d))
			.catch((error) => console.log(error));
	}, []);

	const { Title } = Typography;

	const handleAddRole = (newRoleData) => {
		setLoader(true);
		const newList = [...list];
		newList.push(newRoleData);
		setList(newList);
		setLoader(false);
		toast.success("Role added successfully!");
	};
	return (
		<Fragment bordered={false}>
			<UserPrivateComponent permission={"readAll-role"}>
				{drawer || <CustomTable list={list} onAddRole={handleAddRole} />}
			</UserPrivateComponent>
		</Fragment>
	);
};

export default AddRole;
