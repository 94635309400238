// LeaveStatsBarChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LeaveStatsBarChart = ({ transformedBarData }) => {
  console.log("transformedBarData", transformedBarData);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={transformedBarData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="approved" fill="#0723a1" />
        <Bar dataKey="rejected" fill="#EB131C" />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default LeaveStatsBarChart;
