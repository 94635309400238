import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  DatePicker,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../page-header/PageHeader";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  addShift,
  loadAllShift,
} from "../../redux/rtk/features/shift/shiftSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import moment from "moment";
import { getISOWeek } from "date-fns";
import {
  loadAllLocation,
  loadSingleLocation,
} from "../../redux/rtk/features/location/locationSlice";
import { loadRoomsByLocationId } from "../../redux/rtk/features/rooms/roomSlice";
import {
  CreateShiftManagement,
  updateSingleShift,
  loadSingleShifts,
} from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import { AiOutlineEdit } from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";
import styles from "../leave/leave.module.css";
import { useParams } from "react-router-dom";

function CustomTable({ list, drawer }) {
  const [row, setRow] = useState([list?.user]);
  const { id } = useParams();
  const user_id = localStorage.getItem("id");
  const location = useSelector((state) => state.location.list);
  const RoomsList = useSelector((state) => state.Rooms.loadRoomsid.data);
  const { RangePicker } = DatePicker;
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [shift, setShift] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(list?.shiftFrom).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs(list?.shiftTo).format("YYYY-MM-DD")
  );
  const [shiftUser, setShiftUser] = useState();
  const [sChange, setSChange] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(list?.generalInfo);
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState(list?.location);

  const Today_date = moment(new Date()).format('YYYY-MM-DD')
  //set value in status
  const status = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.status;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const daysId = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.id;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const daysNme = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.day;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const daysDAte = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.shiftDate;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const shiftStartTime = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.startTime;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const shiftEndTime = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.endTime;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const shiftBreakTime = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.breakTime;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const shiftFolderTime = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.folderTime;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const RoomUpdate = (i) => {
    if (list) {
      if (list?.schedule?.length > 0) {
        return list?.schedule[i]?.room;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Monday shift
  const [mId, setMId] = useState();
  const [mDay, setMDay] = useState();
  const [mDate, setMDate] = useState();
  const [mStatus, setMStatus] = useState(false);
  const [mRoom, setMRoom] = useState();
  const [mRoomId, setMRoomId] = useState();
  const [mStartTime, setMStartTime] = useState();
  const [mEndTime, setMEndTime] = useState();
  const [mBreakTime, setMBreakTime] = useState();
  const [mFolderTime, setMFolderTime] = useState();
  // Tuesday shift
  const [tId, setTId] = useState();
  const [tDay, setTDay] = useState();
  const [tDate, setTDate] = useState();
  const [tStatus, setTStatus] = useState(false);
  const [tRoom, setTRoom] = useState();
  const [tRoomId, setTRoomId] = useState();
  const [tStartTime, setTStartTime] = useState();
  const [tEndTime, setTEndTime] = useState();
  const [tBreakTime, setTBreakTime] = useState();
  const [tFolderTime, setTFolderTime] = useState();

  // Wednesday shift
  const [wId, setWId] = useState();
  const [wDay, setWDay] = useState();
  const [wDate, setWDate] = useState();
  const [wStatus, setWStatus] = useState(false);
  const [wRoom, setWRoom] = useState();
  const [wRoomId, setWRoomId] = useState();
  const [wStartTime, setWStartTime] = useState();
  const [wEndTime, setWEndTime] = useState();
  const [wBreakTime, setWBreakTime] = useState();
  const [wFolderTime, setWFolderTime] = useState();

  // Thursday shift
  const [thId, setTHId] = useState();
  const [thDay, setTHDay] = useState();
  const [thDate, setTHDate] = useState();
  const [thStatus, setTHStatus] = useState(false);
  const [thRoom, setTHRoom] = useState();
  const [thRoomId, setTHRoomId] = useState();
  const [thStartTime, setTHStartTime] = useState();
  const [thEndTime, setTHEndTime] = useState();
  const [thBreakTime, setTHBreakTime] = useState();
  const [thFolderTime, setTHFolderTime] = useState();

  // Friday shift
  const [fId, setFId] = useState();
  const [fDay, setFDay] = useState();
  const [fDate, setFDate] = useState();
  const [fStatus, setFStatus] = useState(false);
  const [fRoom, setFRoom] = useState();
  const [fRoomId, setFRoomId] = useState();
  const [fStartTime, setFStartTime] = useState();
  const [fEndTime, setFEndTime] = useState();
  const [fBreakTime, setFBreakTime] = useState();
  const [fFolderTime, setFFolderTime] = useState();

  // Saturday shift
  const [saId, setSAId] = useState();
  const [saDay, setSADay] = useState();
  const [saDate, setSADate] = useState();
  const [saStatus, setSAStatus] = useState(false);
  const [saRoom, setSARoom] = useState();
  const [saRoomId, setSARoomId] = useState();
  const [saStartTime, setSAStartTime] = useState();
  const [saEndTime, setSAEndTime] = useState();
  const [saBreakTime, setSABreakTime] = useState();
  const [saFolderTime, setSAFolderTime] = useState();

  // Sunday shift
  const [suId, setSUId] = useState();
  const [suDay, setSUDay] = useState();
  const [suDate, setSUDate] = useState();
  const [suStatus, setSUStatus] = useState(false);
  const [suRoom, setSURoom] = useState();
  const [suRoomId, setSURoomId] = useState();
  const [suStartTime, setSUStartTime] = useState();
  const [suEndTime, setSUEndTime] = useState();
  const [suBreakTime, setSUBreakTime] = useState();
  const [suFolderTime, setSUFolderTime] = useState();


  useEffect(() => {
    dispatch(loadAllLocation({ status: "true" }));
  }, []);

  const dateFormat = "YYYY/MM/DD";
  const columns = [
    {
      id: 1,
      title: "Emp id",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (employeeId) => (employeeId == null ? "--" : employeeId),
    },
    {
      id: 2,
      title: "Employee Name",
      dataIndex: ["firstName", "lastName"],
      key: "firstName",
      render: (text, record) =>
        record?.firstName == null
          ? "--"
          : record?.firstName + " " + record.lastName,
    },
    {
      id: 3,
      title: "Designation",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.designationHistory[0] ? record?.designationHistory[0]?.designation?.name : "--"
    },
    {
      id: 5,
      title: "Update Shift Details",
      dataIndex: ["firstName", "lastName", "id"],
      key: "id",
      render: (text, record) => (
        <div>
          <button
            onClick={(e) => handleShiftChange(record)}
            type="primary"
            className="text-[16px] font-[600] text-[#23B9F8]"
          >
            <AiOutlineEdit size={27} className="text-[#23B9F8]" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (list) {
      setGeneralInfo(list.generalInfo);
      setLocationName(list?.location);
      setStartDate(dayjs(list?.shiftFrom).format("YYYY-MM-DD"));
      setEndDate(dayjs(list?.shiftTo).format("YYYY-MM-DD"));

      setMStatus(status(0));
      setTStatus(status(1));
      setWStatus(status(2));
      setTHStatus(status(3));
      setFStatus(status(4));
      setSAStatus(status(5));
      setSUStatus(status(6));

      setMId(daysId(0));
      setTId(daysId(1));
      setWId(daysId(2));
      setTHId(daysId(3));
      setFId(daysId(4));
      setSAId(daysId(5));
      setSUId(daysId(6));

      setMDay(daysNme(0));
      setTDay(daysNme(1));
      setWDay(daysNme(2));
      setTHDay(daysNme(3));
      setFDay(daysNme(4));
      setSADay(daysNme(5));
      setSUDay(daysNme(6));

      setMDate(daysDAte(0));
      setTDate(daysDAte(1));
      setWDate(daysDAte(2));
      setTHDate(daysDAte(3));
      setFDate(daysDAte(4));
      setSADate(daysDAte(5));
      setSUDate(daysDAte(6));

      setMRoomId(RoomUpdate(0));
      setTRoomId(RoomUpdate(1));
      setWRoomId(RoomUpdate(2));
      setTHRoomId(RoomUpdate(3));
      setFRoomId(RoomUpdate(4));
      setSARoomId(RoomUpdate(5));
      setSURoomId(RoomUpdate(6));

      setMStartTime(shiftStartTime(0));
      setTStartTime(shiftStartTime(1));
      setWStartTime(shiftStartTime(2));
      setTHStartTime(shiftStartTime(3));
      setFStartTime(shiftStartTime(4));
      setSAStartTime(shiftStartTime(5));
      setSUStartTime(shiftStartTime(6));

      setMEndTime(shiftEndTime(0));
      setTEndTime(shiftEndTime(1));
      setWEndTime(shiftEndTime(2));
      setTHEndTime(shiftEndTime(3));
      setFEndTime(shiftEndTime(4));
      setSAEndTime(shiftEndTime(5));
      setSUEndTime(shiftEndTime(6));

      setMBreakTime(shiftBreakTime(0));
      setTBreakTime(shiftBreakTime(1));
      setWBreakTime(shiftBreakTime(2));
      setTHBreakTime(shiftBreakTime(3));
      setFBreakTime(shiftBreakTime(4));
      setSABreakTime(shiftBreakTime(5));
      setSUBreakTime(shiftBreakTime(6));

      setMFolderTime(shiftFolderTime(0));
      setTFolderTime(shiftFolderTime(1));
      setWFolderTime(shiftFolderTime(2));
      setTHFolderTime(shiftFolderTime(3));
      setFFolderTime(shiftFolderTime(4));
      setSAFolderTime(shiftFolderTime(5));
      setSUFolderTime(shiftFolderTime(6));
    } else {
      dispatch(loadSingleShifts(id));
    }
  }, [list]);

  useEffect(() => {
    if (list) {
      setRow([list?.user]);
      if (list?.location) dispatch(loadRoomsByLocationId(list?.locationId));
    }
  }, [list]);

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const handelRangePicker = (values) => {
    if (values) {
      setStartDate(values[0].format("YYYY-MM-DD"));
      setEndDate(values[1].format("YYYY-MM-DD"));
    }
  };
  var endWeekNumber = getISOWeek(new Date(endDate));

  const currentYear = new Date(endDate).getFullYear();
  function getWeekDatesAndDayNames(weekNumber, year) {
    const result = [];
    const firstDayOfYear = new Date(year, 0, 1);
    const daysToFirstDayOfWeek = 1 - firstDayOfYear.getDay();
    const firstDayOfWeek = new Date(
      year,
      0,
      1 + daysToFirstDayOfWeek + (weekNumber - 1) * 7
    );

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(
        firstDayOfWeek.getFullYear(),
        firstDayOfWeek.getMonth(),
        firstDayOfWeek.getDate() + i
      );
      const dayNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayName = dayNames[currentDate.getDay()];
      result.push({
        date: currentDate.toLocaleDateString("en-US", {
          timeZone: "Asia/Karachi",
        }),
        dayName: dayName,
      });
    }

    return result;
  }

  const weekArray = getWeekDatesAndDayNames(endWeekNumber, currentYear);

  const handleLocationChange = (id) => {
    if (id) {
      setLocationId(id);
      dispatch(loadSingleLocation(id));
      dispatch(loadRoomsByLocationId(id));
    }
  };

  function handleShiftChange(values) {
    setShiftUser(values);
    setShift(true);
  }

  const SubmitCreateShift = async () => {
    if (
      mStatus ||
      tStatus ||
      wStatus ||
      thStatus ||
      fStatus ||
      saStatus ||
      suStatus
    ) {
      if (mStatus) {
        if (mStartTime > mEndTime) {
          return toast.error(
            "your Monday Shift End time is less then Start Time"
          );
        }
      }
      if (tStatus) {
        if (tStartTime > tEndTime) {
          return toast.error(
            "your Tuesday Shift End time is less then Start Time"
          );
        }
      }
      if (wStatus) {
        if (wStartTime > wEndTime) {
          return toast.error(
            "your Wednesday Shift End time is less then Start Time"
          );
        }
      }
      if (thStatus) {
        if (thStartTime > thEndTime) {
          return toast.error(
            "your Thursday Shift End time is less then Start Time"
          );
        }
      }
      if (fStatus) {
        if (fStartTime > fEndTime) {
          return toast.error(
            "your Friday Shift End time is less then Start Time"
          );
        }
      }
      if (saStatus) {
        if (saStartTime > saEndTime) {
          return toast.error(
            "your Saturday Shift End time is less then Start Time"
          );
        }
      }
      if (suStatus) {
        if (suStartTime > suEndTime) {
          return toast.error(
            "your Sunday Shift End time is less then Start Time"
          );
        }
      }
    } else {
      return toast.error("Please Update at least one day Shift");
    }

    const values = {
      shiftFrom: startDate,
      shiftTo: endDate,
      weekNumber: endWeekNumber,
      userId: shiftUser?.id,
      locationId: locationId ? locationId : list.location?.id,
      assignedBy: user_id,
      generalInfo: generalInfo,
      schedule: [
        {
          id: mId,
          day: mDay,
          shiftDate: mDate,
          startTime: mStartTime,
          endTime: mEndTime,
          breakTime: mBreakTime,
          folderTime: mFolderTime,
          roomId: mRoom ? mRoom : mRoomId?.id,
          status: mStatus,
        },
        {
          id: tId,
          day: tDay,
          shiftDate: tDate,
          startTime: tStartTime,
          endTime: tEndTime,
          breakTime: tBreakTime,
          folderTime: tFolderTime,
          roomId: tRoom ? tRoom : tRoomId?.id,
          status: tStatus,
        },
        {
          id: wId,
          day: wDay,
          shiftDate: wDate,
          startTime: wStartTime,
          endTime: wEndTime,
          breakTime: wBreakTime,
          folderTime: wFolderTime,
          roomId: wRoom ? wRoom : wRoomId?.id,
          status: wStatus,
        },
        {
          id: thId,
          day: thDay,
          shiftDate: thDate,
          startTime: thStartTime,
          endTime: thEndTime,
          breakTime: thBreakTime,
          folderTime: thFolderTime,
          roomId: thRoom ? thRoom : thRoomId?.id,
          status: thStatus,
        },
        {
          id: fId,
          day: fDay,
          shiftDate: fDate,
          startTime: fStartTime,
          endTime: fEndTime,
          breakTime: fBreakTime,
          folderTime: fFolderTime,
          roomId: fRoom ? fRoom : fRoomId?.id,
          status: fStatus,
        },
        {
          id: saId,
          day: saDay,
          shiftDate: saDate,
          startTime: saStartTime,
          endTime: saEndTime,
          breakTime: saBreakTime,
          folderTime: saFolderTime,
          roomId: saRoom ? saRoom : saRoomId?.id,
          status: saStatus,
        },
        {
          id: suId,
          day: suDay,
          shiftDate: suDate,
          startTime: suStartTime,
          endTime: suEndTime,
          breakTime: suBreakTime,
          folderTime: suFolderTime,
          roomId: suRoom ? suRoom : suRoomId?.id,
          status: suStatus,
        },
      ],
    };
    const resp = await dispatch(updateSingleShift({ id: id, values: values }));
    if (resp.payload.message === "success") {
      setLoader(false);
      setSChange(false)
      setShift(false);
    } else {
      setLoader(false);
    }
  };



  return (
    <>
      <div className="flex mb-6 items-center justify-between">
        <h5 className="department-list-title text-color-2 text-[24px] font-[600]">
          Update Shift
        </h5>
        <PageTitle title="Back" />
      </div>
      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Week Date Range</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0">
          <div className="ant-card-body">
            <RangePicker
              defaultValue={[moment(list?.shiftFrom), moment(list?.shiftTo)]}
              format={dateFormat}
              onChange={handelRangePicker}
              className="mb-3"
            />
            <h5 className="text-[16px] font-[600] text-gray-400">Week</h5>
            <Form.Item>
              <Input
                className="bg-gray-200"
                value={"WEEK" + "  " + endWeekNumber}
                disabled
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Choose Location</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0">
          <div className="ant-card-body">
            {/* <Form.Item className="!mb-0"> */}
            <Select
              className="mr-2"
              placeholder={locationName?.locationName == undefined ? 'location not found' : locationName?.locationName}
              loading={location?.length === 0}
              onChange={handleLocationChange}
              defaultValue={locationName?.locationName == undefined ? 'location not found' : locationName?.locationName}
            >
              {location &&
                location.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.locationName}
                  </Select.Option>
                ))}
            </Select>
            {/* </Form.Item> */}
          </div>
        </div>
      </div>

      <Card bordered={false} className="ant-shadow rounded-[10px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={!list}
          columns={columnsToShow}
          dataSource={row}
        />
      </Card>

      <Modal
        centered
        open={shift}
        onOk={() => setShift(false)}
        onCancel={() => setShift(false)}
        footer={null}
        width={1000}
        className="shift-modal-custom mt-[30px] mb-[40px]"
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
              borderBottom: "unset !important",
            }}
          >Update Shift Details</div>
        }
      >
        <UserPrivateComponent permission={"create-shift"}>
          <div className="text-center mb-4">
            <h2 className="text-[18px] font-[600]">
              Shift For Staff:&nbsp;
              <span className="font-[500]">
                {shiftUser?.firstName + " " + shiftUser?.lastName}
              </span>
            </h2>
          </div>
          <div className={sChange == true ? "hidden" : "block"}>
            <div className="w-full overflow-auto">
              <table className="userShift-table">
                <thead>
                  <tr>
                    <th>Days</th>
                    <th>Date</th>
                    <th>Room</th>
                    <th>Start time</th>
                    <th>End time</th>
                    <th style={{ width: "15%" }}>Break time&nbsp;(min)</th>
                    <th style={{ width: "15%" }}>Folder time&nbsp;(min)</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={mDate >= Today_date ? false : true}
                          checked={mStatus}
                          onChange={(e) => setMStatus(e.target.checked)}
                        >
                          {mDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={mDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Select
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        disabled={mStatus == false ? "disabled" : mDate >= Today_date ? false : true}
                        onChange={(value) => setMRoom(value)}
                        defaultValue={mRoomId?.roomName}
                      >
                        {RoomsList &&
                            RoomsList?.map((rom) => (
                              <Select.Option key={rom.id} value={rom.id}>
                                {rom?.roomName}
                              </Select.Option>
                            ))
                            }
                      </Select>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            mStartTime == null
                              ? ""
                              : moment(
                                dayjs(mStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={mStatus == false ? "disabled" : mDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setMStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            mEndTime == null
                              ? ""
                              : moment(
                                dayjs(mEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={mStatus == false ? "disabled" : mDate >= Today_date ? false : true}
                          onChange={(time) => setMEndTime(dayjs(time).format())}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={mBreakTime}
                          onChange={(value) => setMBreakTime(value)}
                          disabled={mStatus == false ? "disabled" : mDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={mFolderTime}
                          onChange={(value) => setMFolderTime(value)}
                          disabled={mStatus == false ? "disabled" : mDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={tDate >= Today_date ? false : true}
                          checked={tStatus}
                          onChange={(e) => setTStatus(e.target.checked)}
                        >
                          {tDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={tDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={tStatus == false ? "disabled" : tDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setTRoom(value)}
                        defaultValue={tRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            tStartTime == null
                              ? ""
                              : moment(
                                dayjs(tStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={tStatus == false ? "disabled" : tDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setTStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            tEndTime == null
                              ? ""
                              : moment(
                                dayjs(tEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={tStatus == false ? "disabled" : tDate >= Today_date ? false : true}
                          onChange={(time) => setTEndTime(dayjs(time).format())}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={tBreakTime}
                          onChange={(value) => setTBreakTime(value)}
                          disabled={tStatus == false ? "disabled" : tDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={tFolderTime}
                          onChange={(value) => setTFolderTime(value)}
                          disabled={tStatus == false ? "disabled" : tDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={wDate >= Today_date ? false : true}
                          checked={wStatus}
                          onChange={(e) => setWStatus(e.target.checked)}
                        >
                          {wDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={wDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={wStatus == false ? "disabled" : wDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setWRoom(value)}
                        defaultValue={wRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            wStartTime == null
                              ? ""
                              : moment(
                                dayjs(wStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={wStatus == false ? "disabled" : wDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setWStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            wEndTime == null
                              ? ""
                              : moment(
                                dayjs(wEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={wStatus == false ? "disabled" : wDate >= Today_date ? false : true}
                          onChange={(time) => setWEndTime(dayjs(time).format())}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={wBreakTime}
                          onChange={(value) => setWBreakTime(value)}
                          disabled={wStatus == false ? "disabled" : wDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={wBreakTime}
                          onChange={(value) => setWFolderTime(value)}
                          disabled={wStatus == false ? "disabled" : wDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={thDate >= Today_date ? false : true}
                          checked={thStatus}
                          onChange={(e) => setTHStatus(e.target.checked)}
                        >
                          {thDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={thDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={thStatus == false ? "disabled" : thDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setTHRoom(value)}
                        defaultValue={thRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            thStartTime == null
                              ? ""
                              : moment(
                                dayjs(thStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={thStatus == false ? "disabled" : thDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setTHStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            thEndTime == null
                              ? ""
                              : moment(
                                dayjs(thEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={thStatus == false ? "disabled" : thDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setTHEndTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={thBreakTime}
                          onChange={(value) => setTHBreakTime(value)}
                          disabled={thStatus == false ? "disabled" : thDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={thBreakTime}
                          onChange={(value) => setTHFolderTime(value)}
                          disabled={thStatus == false ? "disabled" : thDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={fDate >= Today_date ? false : true}
                          checked={fStatus}
                          onChange={(e) => setFStatus(e.target.checked)}
                        >
                          {fDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={fDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={fStatus == false ? "disabled" : fDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setFRoom(value)}
                        defaultValue={fRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            fStartTime == null
                              ? ""
                              : moment(
                                dayjs(fStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={fStatus == false ? "disabled" : fDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setFStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            fEndTime == null
                              ? ""
                              : moment(
                                dayjs(fEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={fStatus == false ? "disabled" : fDate >= Today_date ? false : true}
                          onChange={(time) => setFEndTime(dayjs(time).format())}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={fBreakTime}
                          onChange={(value) => setFBreakTime(value)}
                          disabled={fStatus == false ? "disabled" : fDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={fBreakTime}
                          onChange={(value) => setFFolderTime(value)}
                          disabled={fStatus == false ? "disabled" : fDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={saDate >= Today_date ? false : true}
                          checked={saStatus}
                          onChange={(e) => setSAStatus(e.target.checked)}
                        >
                          {saDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={saDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={saStatus == false ? "disabled" : saDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setSARoom(value)}
                        defaultValue={saRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            saStartTime == null
                              ? ""
                              : moment(
                                dayjs(saStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={saStatus == false ? "disabled" : saDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setSAStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            saEndTime == null
                              ? ""
                              : moment(
                                dayjs(saEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={saStatus == false ? "disabled" : saDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setSAEndTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={saBreakTime}
                          onChange={(value) => setSABreakTime(value)}
                          disabled={saStatus == false ? "disabled" : saDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={saBreakTime}
                          onChange={(value) => setSAFolderTime(value)}
                          disabled={saStatus == false ? "disabled" : saDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Checkbox
                          disabled={suDate >= Today_date ? false : true}
                          checked={suStatus}
                          onChange={(e) => setSUStatus(e.target.checked)}
                        >
                          {suDay}
                        </Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <Input value={suDate} disabled />
                      </Form.Item>
                    </td>
                    <td>
                      {/* <Form.Item style={{ marginBottom: "10px" }}> */}
                      <Select
                        disabled={suStatus == false ? "disabled" : suDate >= Today_date ? false : true}
                        placeholder="Select Room"
                        loading={RoomsList?.length == 0}
                        className="w-[130px] mb-[10px]"
                        onChange={(value) => setSURoom(value)}
                        defaultValue={suRoomId?.roomName}
                      >
                        {RoomsList &&
                          RoomsList?.map((rom) => (
                            <Select.Option key={rom.id} value={rom.id}>
                              {rom?.roomName}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* </Form.Item> */}
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            suStartTime == null
                              ? ""
                              : moment(
                                dayjs(suStartTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={suStatus == false ? "disabled" : suDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setSUStartTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <TimePicker
                          defaultValue={
                            suEndTime == null
                              ? ""
                              : moment(
                                dayjs(suEndTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )
                          }
                          disabled={suStatus == false ? "disabled" : suDate >= Today_date ? false : true}
                          onChange={(time) =>
                            setSUEndTime(dayjs(time).format())
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={suBreakTime}
                          onChange={(value) => setSUBreakTime(value)}
                          disabled={suStatus == false ? "disabled" : suDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        <InputNumber
                          defaultValue={suBreakTime}
                          onChange={(value) => setSUFolderTime(value)}
                          disabled={suStatus == false ? "disabled" : suDate >= Today_date ? false : true}
                          className="w-full h-[34px] !py-[1px]"
                          placeholder="00:00 min"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setSChange(true)}
                className="py-2 flex items-center text-[16px] font-[500] hover:text-[#0723a1]"
              >
                Next&nbsp;
                <BsArrowRight />
              </button>
            </div>
          </div>
          <div className={sChange == false ? "hidden" : "block"}>
            <p>General information</p>
            <TextArea
              value={generalInfo}
              onChange={(e) => setGeneralInfo(e.target.value)}
              placeholder="General information...."
              showCount
              rows={7}
              className={`${styles.CommentBox}`}
            />
            <div className="flex justify-end items-center gap-4 mt-6">
              <button
                onClick={() => setSChange(false)}
                className="p-4 flex items-center text-[16px] font-[500] hover:text-[#0723a1]"
              >
                <BsArrowLeft />
                &nbsp; Previous
              </button>
              <button
                onClick={SubmitCreateShift}
                className="py-2 px-6 text-[18px] font-[500] text-white rounded-[15px]  bg-[#E42976] border-0"
              >
                Save
              </button>
            </div>
          </div>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const UpdateShift = ({ drawer }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const single_shift = useSelector(
    (state) => state.ShiftManagement.SingleShift
  );
  const single_user_location = useSelector((state) => state.location.location);
  useEffect(() => {
    dispatch(loadSingleShifts(id));
  }, []);
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={single_shift} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default UpdateShift;
