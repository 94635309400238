import { Button, Col, Form, Input, Modal, Row, TimePicker, Typography } from "antd";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BtnEditSvg from "../Button/btnEditSvg";
import { updateShift } from "../../../redux/rtk/features/shift/shiftSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";
const ShiftEditPopup = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams("id");
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { loading } = useSelector((state) => state.shift);
	const navigate = useNavigate();
	const { Title } = Typography;

	const onFinish = (values) => {
		dispatch(updateShift({ id: id, values: values }));
		setIsModalOpen(false);
		navigate(-1);
	};

	const [initialValues, setInitialValues] = useState({
		name: data?.name || "",
		startTime: dayjs(data?.startTime),
		endTime: dayjs(data?.endTime),
	});

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<button onClick={showModal}   >
				<BiEdit size={30} style={{color:"#0723A1"}} />
			</button>
			<Modal
				centered
				// title='Edit Shift'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				className="bank-holiday-modal"

			>
				<Row>
					<Col
						xs={{ span: 12, offset: 6 }}
						sm={{ span: 12, offset: 6 }}
						lg={{ span: 12, offset: 6 }}
						xl={{ span: 12, offset: 6 }}
						md={{ span: 12, offset: 6 }}
					>
						<Title level={4} className='m-2 mt-5 mb-5 text-center'>
							Update shift
						</Title>
						<Form
							form={form}
							style={{ marginBottom: "40px" }}
							eventKey='shift-form'
							name='basic'
							layout="vertical"
							initialValues={initialValues}
							// labelCol={{
							// 	offset: 6,
							// 	span: 12,
							// }}
							// wrapperCol={{
							// 	span: 12,
							// 	offset: 6,
							// }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete='off'>
							<div>
								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Name'
									name='name'
									rules={[
										{
											required: true,
											message: "Please input your shift!",
										},
									]}>
									<Input className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Start Time'
									name='startTime'
									rules={[
										{
											required: true,
											message: "Please input your shift!",
										},
									]}>
									<TimePicker name='startTime' className="border-none ant-shadow " />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "20px" }}
									label='End Time'
									name='endTime'
									rules={[
										{
											required: true,
											message: "Please input your shift!",
										},
									]}>
									<TimePicker name='endTime' className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px"}}
									// wrapperCol={{
									// 	offset: 6,
									// 	span: 12,
									// }}
									>
									<Button
										type='primary'
										size='medium'
										htmlType='submit'
										block
										loading={loading}>
										Update Shift
									</Button>
								</Form.Item>
							</div>
						</Form>
					</Col>
				</Row>


			</Modal>
		</>
	);
};
export default ShiftEditPopup;
