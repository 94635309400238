import "bootstrap-icons/font/bootstrap-icons.css";
import { useState } from "react";
import "./user.css";
import { Segmented, Table, Pagination, Button, Card, Tag } from "antd";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import { loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import ViewBtn from "../Buttons/ViewBtn";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import AttendBtn from "../Buttons/AttendBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { Link } from "react-router-dom";
import DelModal from "../UI/PopUp/DeleteWarningModal";
import { MdDelete } from "react-icons/md";
import SearchIcon from '@mui/icons-material/Search';

import { AudioOutlined } from '@ant-design/icons';
import { Input } from 'antd';

function CustomTable({ list, loading }) {
  const [row, setRow] = useState([]);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("true");
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [deluser, setDelUser] = useState(false);
  const [delUserId, setDelUserId] = useState();
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  // Filter data based on pagination
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = list ? list.slice(startIndex, endIndex) : [];
  const totalItems = list ? list.length : 0;
  const [searchTerm, setSearchTerm] = useState('');
  const [filterList, setFilterList] = useState([]);

  const columns = [
    {
      // id: 1,
      title: "Emp id",
      dataIndex: "employeeId",
      key: "id",
    },
    {
      // id: 2,
      title: "Name",
      key: "fullName",
      render: ({ firstName, lastName }) =>
        (firstName + " " + lastName).toUpperCase(),
    },
    {
      // id: 3,
      title: "Email",
      dataIndex: "email",
      key: "userName",
    },
    {
      // id: 4,
      title: "Designation",
      dataIndex: "designationHistory",
      key: "designationHistory",
      render: (record) =>
        record.length > 0 ? record[0].designation.name : "N/A",
    },

    {
      // id: 4,
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (record) => record?.name,
    },

    {
      // id: 5,
      title: "Emp-Status",
      dataIndex: "employmentStatus",
      key: "employmentStatus",
      render: (record) => (record?.name ? record?.name : "N/A"),
    },
    {
      // id: 6,
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (record) => (record?.name ? record?.name : "N/A"),
    },

    // {
    //   // id: 7,
    //   title: "Shift",
    //   dataIndex: "shift",
    //   key: "shift",
    //   render: (record) => (
    //     <>
    //       <div className="grid">
    //         <div>{record?.name ? record?.name : "N/A"}</div>
    //         <div>{record?.workHour ? record?.workHour + " hr" : "N/A"}</div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      // id: 8,
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (record) => (record?.locationName ? record?.locationName : "N/A"),
    },
    // {
    //   // id: 8,
    //   title: "Bank Holidays",
    //   dataIndex: "remaingbankallowedleave",
    //   key: "remaingbankallowedleave",
    // },
    {
      // id: 8,
      title: "Remaining Annual Leaves",
      dataIndex: "remainingannualallowedleave",
      key: "remainingannualallowedleave",
    },
    {
      // id: 8,
      title: "Application status",
      dataIndex: "applicationStatus",
      key: "applicationStatus",
      render: (applicationStatus) => (
        <Tag
          color={
            applicationStatus === "PENDING"
              ? "yellow"
              : applicationStatus === "APPROVED"
                ? "green"
                : applicationStatus === "REJECTED"
                  ? "red"
                  : "red"
          }
        >
          {applicationStatus}
        </Tag>
      ),
    },

    {
      // id: 9,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <div className="flex justify-start">
          <UserPrivateComponent permission={"readSingle-user"}>
            <ViewBtn path={`/admin/hr/staffs/${id}/`} />
          </UserPrivateComponent>

          <UserPrivateComponent permission={"readSingle-attendance"}>
            <AttendBtn path={`/admin/attendance/user/${id}`} />
          </UserPrivateComponent>

          <UserPrivateComponent permission="delete-user">
            <button
              onClick={(e) => onDelete(id)}
              className="text-lg text-[#FF0000] font-bold px-2 mr-2"
            >
              <MdDelete className="text-[25px] text-[#FF0000]" />
            </button>
          </UserPrivateComponent>
        </div>
      ),
    },
  ];

  function onDelete(id) {
    setDelUserId(id);
    setDelUser(true);
  }

  //make a onChange function
  const onChange = (value) => {
    setStatus(value);
    dispatch(loadAllStaff());
    dispatch(loadAllLocation({ status: value }));
  };

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      makeRow();
    }
  }, [list]);
  const makeRow = () => {
    const filterdata = list;
    const data =
      Array.isArray(filterdata) && filterdata.length > 0
        ? filterdata.map((data, index) => ({
          employeeId: data?.employeeId == null ? '-' : data?.employeeId,
          fullName:
            data?.firstName == null
              ? "-"
              : data?.firstName + "-" + data?.lastName,
          userName: data?.userName,
          Designation: data?.designationHistory[0]?.designation?.name,
          role: data?.role?.name == null ? "-" : data?.role?.name,
          EmpStatus:
            data?.employmentStatus?.name == null
              ? "-"
              : data?.employmentStatus?.name,
          department:
            data?.department?.name == null ? "-" : data?.department?.name,
          // shift: data?.shift?.name == null ? "-" : data?.shift?.name,
          location:
            data?.location?.locationName == null
              ? "-"
              : data?.location?.locationName,
          BankHolidays:
            data?.remaingbankallowedleave == null
              ? "-"
              : data?.remaingbankallowedleave,
          RemainingAnnualLeaves:
            data?.remainingannualallowedleave == null
              ? "-"
              : data?.remainingannualallowedleave,
          Applicationstatus:
            data?.applicationStatus == null ? "-" : data?.applicationStatus,
        }))
        : filterdata;
    setRow(data);
  };

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const filteredEmployees = list.filter(employee => {
    const searchLower = searchTerm.toLowerCase();
    return (
        employee.firstName.toLowerCase().includes(searchLower) ||
        employee.lastName.toLowerCase().includes(searchLower) ||
        (employee.email && employee.email.toLowerCase().includes(searchLower)) ||
        (employee.designationHistory.length > 0 &&
            employee.designationHistory[0].designation.name.toLowerCase().includes(searchLower)) ||
        (employee.role && employee.role.name.toLowerCase().includes(searchLower)) ||
        (employee.employmentStatus && employee.employmentStatus.name.toLowerCase().includes(searchLower)) ||
        (employee.department && employee.department.name.toLowerCase().includes(searchLower)) ||
        (employee.location && employee.location.locationName.toLowerCase().includes(searchLower)) ||
        (employee.applicationStatus && employee.applicationStatus.toLowerCase().includes(searchLower))
    );
  });


  return (
    <>
      <div>
        <h4 className="text-[24px] font-[600] mb-2">Employee List</h4>
      </div>
      <div className="mr-2 rounded mt-5">
        <div className="flex my-2 justify-between items-center flex-wrap">
          {list && (
            <div className="flex  mr-4">
              <div className="flex items-center">
                <ColVisibilityDropdown
                  options={columns}
                  columns={columns}
                  columnsToShowHandler={columnsToShowHandler}
                />
              </div>
              <div>
                <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                  <CSVLink
                    data={row}
                    className="btn btn-dark btn-sm hover:!text-white text-[15px] font-[400]"
                    style={{ marginTop: "5px" }}
                    filename="EmployeeList"
                  >
                    CSV
                  </CSVLink>
                </CsvLinkBtn>
              </div>

              {/* <div>
								<Segmented
									className='text-center rounded text-red-500'
									size='middle'
									options={[
										{
											label: (
												<span>
													<i className='bi bi-person-lines-fill'></i> Active
												</span>
											),
											value: "true",
										},
										{
											label: (
												<span>
													<i className='bi bi-person-dash-fill'></i> Inactive
												</span>
											),
											value: "false",
										},
									]}
									value={status}
									onChange={onChange}
								/>
							</div> */}
            </div>
          )}

          <div style={{ display: 'flex', width: '25%', justifyContent: 'space-between', alignItems: 'center', marginLeft: 'auto' }}>
            {/* <div style={{ width: '90%', marginLeft: -20 }}>
              <h1>Employee Search :</h1>
            </div> */}

          </div>

          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>

            <Input
              placeholder="Search by name"
              prefix={<SearchIcon />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          
            <div>
              <Link
                style={{ whiteSpace: 'pre' }}
                to="/admin/hr/staffs/new"
                className="bg-[#E42976] text-[15px] font-[400] text-white border-0 h-[30px] py-0 px-[30px] flex items-center text-center rounded-[5px]"
              >
                Add Employee
              </Link>
            </div>
          </div>
        </div>
        <Card
          className="emp_listing_card rounded-[15px] ant-shadow p-0"
          bordered={false}
        >
          <Table
            scroll={{ x: true }}
            loading={loading}
            pagination={false} // Disable the default pagination
            columns={columnsToShow}
            dataSource={searchTerm ? filteredEmployees : addKeys(currentData)} // Display the current page data
          />
          <Pagination
            className="mt-3 flex justify-end"
            current={currentPage}
            pageSize={pageSize}
            total={totalItems} // Use totalItems instead of list.length
            onChange={handleChangePage}
            showSizeChanger={false}
          />
        </Card>
      </div>
      <DelModal
        open={deluser}
        onClose={setDelUser}
        userId={delUserId}
        content={"Are you sure to delete this user?"}
        type={"userdel"}
      />
    </>
  );
}

const GetAllCust = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.users.list);
  const loading = useSelector((state) => state.users.loading);

  useEffect(() => {
    dispatch(loadAllStaff());
    dispatch(loadAllLocation({ status: "true" }));
  }, []);

  // useEffect(() => {
  //   deleteHandler(list, deletedId);
  // }, [deletedId, list]);

  return (
    <UserPrivateComponent permission={"readAll-user"}>
      <div className="card card-custom border-0">
        <div className="card-body">
          <CustomTable list={list} loading={loading} />
        </div>
      </div>
    </UserPrivateComponent>
  );
};

export default GetAllCust;
