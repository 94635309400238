import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProjectTasks,
  loadAllProjectTasks,
} from "../../../redux/rtk/features/projectManagement/project/projectTask/projectTask";
import { MdDelete } from "react-icons/md";
import DelModal from "../../UI/PopUp/DeleteWarningModal";

const DeleteTask = ({ id }) => {
  const [delModal, setDelModal] = useState(false)
  const onDelete = () => {
    setDelModal(true)
  };

  return (
    <>
      <button type="primary" onClick={onDelete} className="mr-3 ml-2">
        <MdDelete size={25} color="#FF0000" />
      </button>

      <DelModal
        open={delModal}
        onClose={setDelModal}
        TaskId={id}
        content={"Are you sure to delete this Task?"}
        type={"TaskDel"}

      />
    </>
  );
};
export default DeleteTask;
