import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import styles from "./department.module.css";

const data = [
    { name: 'Pending', value: 60},
    { name: 'Approved', value:50 },
    { name: 'Rejected', value: 20 },
  ];
  
  const COLORS = ['#7BCDF2', '#0723A1', '#EAEAEA'];
  

const PendingAccountsPieChart = ({UserData}) => {
    return (
        <ResponsiveContainer width={300} height={140}>
            <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <Pie
                    data={UserData}
                    cx={90}
                    cy={80}
                    innerRadius={40}
                    outerRadius={60}
                    fill="#7BCDF2"
                    paddingAngle={0}
                    dataKey="value"
                >
                    {UserData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PendingAccountsPieChart;
