import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Table,
  Typography,
  Modal,
} from "antd";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  addSinglePublicHoliday,
  loadAllPublicHoliday,
} from "../../redux/rtk/features/publicHoliday/publicHolidaySlice";
import dayjs from "dayjs";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function CustomTable({ list, loading, drawer }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [addHoliday, setAddHoliday] = useState(false);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const { Title } = Typography;
  const dispatch = useDispatch();

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD/MM/YYYY"),
    },

    {
      id: 3,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <UserPrivateComponent permission={"readSingle-publicHoliday"}>
          <ViewBtn path={`/admin/holiday/public/${id}/`} />
        </UserPrivateComponent>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const onFinish = async (values) => {
    setLoader(true);
    const resp = await dispatch(addSinglePublicHoliday(values));

    if (resp.payload.message === "success") {
      setLoader(false);
      form.resetFields();
      dispatch(loadAllPublicHoliday());
      setAddHoliday(false);
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
  };
  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data.id,
          Name: data.name == null ? "-" : data.name,
          Date: data.date == null ? "-" : dayjs(data.date).format("YYYY-MM-DD"),
          Created_At:
            data.createdAt == null
              ? "-"
              : dayjs(data.createdAt).format("YYYY-MM-DD"),
        }))
      : "";
  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        Bank Holiday List
      </h5>

      <div className="text-center my-2 flex justify-between items-center flex-wrap">
        <div className="flex items-center">
          {list && (
            <div>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
          )}
          {list && (
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={CSVlist}
                  className="btn btn-dark btn-sm hover:text-white text-[15px] font-[400]"
                  filename="public-holiday"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          )}
        </div>
        <div>
          <Button
            className="px-5 text-[15px] font-[400] bg-[#E42976] text-white border-0"
            type="button"
            onClick={() => setAddHoliday(true)}
          >
            Add Bank Holiday
          </Button>
        </div>
      </div>

      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={!list || loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Bank Holiday
          </div>
        }
        centered
        open={addHoliday}
        onOk={() => setAddHoliday(false)}
        onCancel={() => setAddHoliday(false)}
        footer={null}
        className="global-modal-custom"
      >
        <UserPrivateComponent permission={"create-publicHoliday"}>
          <Row justify={"center"}>
            <Col
              xs={{ span: 20, offset: 1 }}
              sm={{ span: 20, offset: 1 }}
              lg={{ span: 17, offset: 1 }}
              xl={{ span: 17, offset: 1 }}
              md={{ span: 20, offset: 1 }}
            >
              <Form
                style={{ marginBottom: "40px" }}
                form={form}
                layout="vertical"
                eventKey="department-form"
                name="basic"
                // labelCol={{
                // 	offset:6,
                // 	span: 12
                // }}
                // wrapperCol={{
                // 	offset:6,
                // 	span: 12
                // }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input name!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="New Year"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    label="Date"
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please input date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="border-none ant-shadow"
                      placeholder="Select Date"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { span: 20, offset: 0 },
                      sm: { span: 20, offset: 2 },
                      md: { span: 20, offset: 2 },
                      lg: { span: 24, offset: 0 },
                      xl: { span: 24, offset: 0 },
                    }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="submit"
                      className=" bg-[#E42976] text-white border-0"
                      size="medium"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Add Bank Holiday
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const AddPublicHoliday = ({ drawer }) => {
  const { list, loading } = useSelector((state) => state.publicHoliday);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllPublicHoliday());
  }, []);

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-publicHoliday"}>
        {drawer || <CustomTable list={list} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddPublicHoliday;
