import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  Switch,
  Select,
} from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllRooms,
  updateRoom,
  addRooms,
  deleteRoom,
} from "../../redux/rtk/features/rooms/roomSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import Loader from "../loader/loader";
import DelModal from "../UI/PopUp/DeleteWarningModal";

function CustomTable({ list, drawer, loading }) {
  const user_id = localStorage.getItem("id");
  const location = useSelector((state) => state.location.list);
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [loader, setLoader] = useState(false);
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [room, setRoom] = useState(false);
  const [romModal, setRomModal] = useState(false);
  const [roomdelId, setRoomDelId] = useState();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Room Name",
      dataIndex: "roomName",
      key: "roomName",
    },
    {
      title: "Location Name",
      dataIndex: "location",
      key: "location",
      render: (location) => (location == null ? "--" : location?.locationName),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
    },

    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => dayjs(updatedAt).format("YYYY-MM-DD"),
    },
    {
      title: "Created By",
      dataIndex: "user",
      key: "user",
      render: (user) =>
        user.firstName == null ? "-" : user.firstName + " " + user.lastName,
    },
    {
      title: "Status",
      dataIndex: ["status", "id"],
      key: "status",
      render: (text, record) => (
        <>
          <Switch
            checked={record.status}
            onChange={(e) =>
              handleSwitchChange(record.id, { status: !record.status })
            }
          />
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <>
          <div className="flex items-center">
            <UserPrivateComponent permission={"update-room"}>
              <Link to={`/all/room/${id}/`}>
                <button
                  type="primary"
                  className="hover:bg-[#23b8f81f] rounded-[50%] flex p-1 mr-2"
                >
                  <AiOutlineEdit className="text-[25px] text-[#23B9F8]" />
                </button>
              </Link>
            </UserPrivateComponent>
            <UserPrivateComponent permission={"delete-room"}>
              <button
                type="primary"
                onClick={() => RoomDelete(id)}
                className="mr-3 ml-2"
              >
                <MdDelete className="text-[25px] text-[#FF0000]" />
              </button>
            </UserPrivateComponent>
          </div>
        </>
      ),
    },
  ];

  const handleSwitchChange = async (id, checked) => {
    setLoader(true);
    const resp = await dispatch(updateRoom({ id: id, checked: checked }));
    if (resp.payload.message === "success") {
      setLoader(false);
      dispatch(loadAllRooms());
    } else {
      setLoader(false);
    }
  };

  const RoomDelete = async (id) => {
    setRoomDelId(id);
    setRomModal(true);
  };

  useEffect(() => {
    dispatch(loadAllLocation({ status: "true" }));
  }, []);

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr?.map((i) => ({ ...i, key: i.id }));

  const onFinish = async (values) => {
    const value = {
      ...values,
      userId: user_id,
    };

    setLoader(true);
    const resp = await dispatch(addRooms(value));

    if (resp.payload.message === "success") {
      setLoader(false);
      form.resetFields();
      dispatch(loadAllRooms());
      setRoom(false);
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
    setLoader(false);
  };

  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data?.id,
          Room_Name: data?.roomName == null ? "-" : data?.roomName,
          Location_Name:
            data?.location == null ? "-" : data?.location?.locationName,
          Created_At:
            data?.createdAt == null
              ? "-"
              : dayjs(data?.createdAt).format("YYYY-MM-DD"),
          updated_At:
            data?.updatedAt == null
              ? "-"
              : dayjs(data?.updatedAt).format("YYYY-MM-DD"),
          Created_By:
            data?.user == null
              ? "-"
              : data?.user?.firstName + " " + data?.user?.lastName,
          Room_Status: data?.status == null ? "-" : data?.status,
        }))
      : "Not Data found";

  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        All Rooms
      </h5>

      <div className="flex justify-between items-center mb-2 mx-4 flex-wrap">
        {list && (
          <>
            <div className="flex items-center">
              <div>
                <ColVisibilityDropdown
                  options={columns}
                  columns={columns}
                  columnsToShowHandler={columnsToShowHandler}
                />
              </div>
              <div>
                {list && list.length > 0 ? (
                  <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                    <CSVLink
                      data={CSVlist}
                      className="btn btn-dark btn-sm text-white text-[15px] font-[400]"
                      filename="All-Rooms"
                    >
                      CSV
                    </CSVLink>
                  </CsvLinkBtn>
                ) : (
                  <div className="pl-2">No data to export</div>
                )}
              </div>
            </div>
          </>
        )}
        <div>
          <UserPrivateComponent permission={"create-room"}>
            <Button
              type="button"
              className="text-[15px] font-[400]  bg-[#E42976] text-white border-0"
              onClick={() => setRoom(true)}
            >
              Add Room
            </Button>
          </UserPrivateComponent>
        </div>
      </div>
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        {list ? (
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columnsToShow}
            dataSource={list ? addKeys(list) : []}
          />
        ) : (
          <Loader />
        )}
      </Card>

      <Modal
        centered
        open={room}
        onOk={() => setRoom(false)}
        onCancel={() => setRoom(false)}
        footer={null}
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Room
          </div>
        }
        className="global-modal-custom"
        width={1000}
      >
        <UserPrivateComponent permission={"create-room"}>
          <Row justify={"center"}>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              lg={{ span: 22 }}
              xl={{ span: 22 }}
              md={{ span: 22 }}
            >
              <Form
                form={form}
                style={{ marginBottom: "40px" }}
                eventKey="room-form"
                name="basic"
                layout="vertical"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    name="locationId"
                    rules={[
                      {
                        required: true,
                        message: "Please Select your Location",
                      },
                    ]}
                  >
                    <Select
                      className="border-none ant-shadow"
                      placeholder="Select Location"
                      loading={location?.length === 0}
                    >
                      {location &&
                        location.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.locationName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "25px" }}
                    name="roomName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Room Name!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="Enter Room Name!"
                    />
                  </Form.Item>

                  <Form.Item
                    labelCol={{
                      xs: { span: 6, offset: 0 },
                      sm: { span: 6, offset: 0 },
                      xl: { span: 6, offset: 9 },
                      lg: { span: 6, offset: 9 },
                      md: { span: 6, offset: 9 },
                    }}
                    wrapperCol={{
                      xs: { span: 6, offset: 0 },
                      sm: { span: 6, offset: 0 },
                      xl: { span: 6, offset: 9 },
                      lg: { span: 6, offset: 9 },
                      md: { span: 6, offset: 9 },
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="submit"
                      className="bg-[#E42976] text-white border-0"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
      <DelModal
        open={romModal}
        onClose={setRomModal}
        RoomId={roomdelId}
        content={"Are you sure to delete this Room?"}
        type={"Roomdel"}
      />
    </>
  );
}

const AllRooms = ({ drawer }) => {
  const roomlist = useSelector((state) => state.Rooms.Roomslist);
  const loading = useSelector((state) => state.Rooms.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllRooms());
  }, []);

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={roomlist} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AllRooms;
