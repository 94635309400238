import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Popover, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { CSVLink } from "react-csv";
import ViewBtn from "../Buttons/ViewBtn";
import dayjs from "dayjs";
import BtnLoader from "../loader/BtnLoader";

import LeavePolicyEdit from "../UI/PopUp/LeavePolicyEditPopup";
import {
	clearWeeklyHoliday,
	deleteWeeklyHoliday,
	loadSingleWeeklyHoliday,
} from "../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
import WeeklyHolidayEdit from "../UI/PopUp/WeeklyHolidayEditPopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { BsTrash } from "react-icons/bs";

//PopUp

const CustomTable = ({ list, data }) => {
	const [columnsToShow, setColumnsToShow] = useState([]);

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},

		{
			id: 2,
			title: " Name",
			key: "name",

			render: ({ firstName, lastName }) => firstName + " " + lastName,
		},

		{
			id: 6,
			title: "Start Day",
			key: "startDay",
			render: () => data?.startDay,
		},

		{
			id: 6,
			title: "End Day",
			key: "endDay",
			render: () => data?.endDay,
		},

		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => (
				<UserPrivateComponent permission={"readSingle-user"}>
					<ViewBtn path={`/admin/hr/staffs/${id}/`} />
				</UserPrivateComponent>
			),
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data.id,
          Name:
            data?.firstName == null
              ? "-"
              : data?.firstName + "" + data?.lastName,
          Start_Day: data.startDay == null ? "-" : data.startDay,
          End_Day: data.endDay == null ? "-" : data.endDay,
        }))
      : "";

  return (
    <>
      <div>
        {list && (
          <div className="flex justify-between">
            <div className="mt-3">
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={CSVlist}
                  filename="user_weekly_Holiday"
                  className="text-white"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          </div>
        )}
        <Table
          loading={!list}
          columns={columnsToShow}
          dataSource={addKeys(list)}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 720 }}
        />
      </div>
    </>
  );
};

const DetailWeeklyHoliday = () => {
	const { id } = useParams();
	let navigate = useNavigate();
	const { weeklyHoliday, loading } = useSelector(
		(state) => state.weeklyHoliday
	);

	//dispatch
	const dispatch = useDispatch();

	//Delete Supplier
	const onDelete = async () => {
		try {
			const resp = await dispatch(deleteWeeklyHoliday(id));
			if (resp.payload.message === "success") {
				return navigate(-1);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		dispatch(loadSingleWeeklyHoliday(id));

		return () => {
			dispatch(clearWeeklyHoliday());
		};
	}, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	if (!isLogged) {
		return <Navigate to={"/admin/auth/login"} replace={true} />;
	}

	return (
		<div>
						{/* <PageTitle title=' Back  ' /> */}

			<h5 className='department-list-title text-color-2 text-[24px] font-[600] mb-6'>
				Employee List
			</h5>
			<UserPrivateComponent permission={"readSingle-weeklyHoliday"}>
				<Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
					{weeklyHoliday ? (
						<Fragment key={weeklyHoliday.id}>
							<div>
								<div className='flex justify-between'>
									<h3 className={"text-xl"}>
										{/* ID : {weeklyHoliday.id} |  */}
										Name: {weeklyHoliday.name}
										
									</h3>
									<div className='flex justify-end mr-2 '>
										<UserPrivateComponent permission={"update-weeklyHoliday"}>
											<WeeklyHolidayEdit data={weeklyHoliday} />
										</UserPrivateComponent>
										{!loading ? (
											<UserPrivateComponent permission={"delete-weeklyHoliday"}>
												<button className='ml-4' onClick={onDelete}>
													{/* <BtnDeleteSvg size={30} /> */}
													<BsTrash name='Delete' size={25} style={{color:"#EB131C"}} />

												</button>
											</UserPrivateComponent>
										) : (
											<BtnLoader />
										)}
									</div>
								</div>
								<CustomTable list={weeklyHoliday.user} data={weeklyHoliday} />
							</div>
						</Fragment>
					) : (
						<Loader />
					)}
				</Card>
			</UserPrivateComponent>
		</div>
	);
};

export default DetailWeeklyHoliday;
