import { Button, Card, Col, Form, Input, Modal, Row, TimePicker, Typography } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateLocation } from "../../../redux/rtk/features/location/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";

const LocationEditPopup = ({ data }) => {
	const { Title } = Typography;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams("id");
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { loading } = useSelector((state) => state.location);
	const navigate = useNavigate();
	const onFinish = (values) => {
		dispatch(updateLocation({ id: id, values: values }));
		setIsModalOpen(false);
		navigate(-1);
	};

	const [initialValues, setInitialValues] = useState({
		locationName: data?.locationName || "",
		longitude: data?.longitude || "",
		latitude: data?.latitude || "",
	});


	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding location");
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<button onClick={showModal}>
				<AiOutlineEdit size={30} className=" text-[#23B9F8]" />
			</button>
			<Modal
				centered
				// title='Edit Location'
				open={isModalOpen}
				onOk={form.submit}
				onCancel={handleCancel}
				footer={null}
				width={500}
				className="bank-holiday-modal"

			>
				<Row gutter={16}>
					<Col
						xs={{ span: 16, offset: 3 }}
						sm={{ span: 16, offset: 3 }}
						lg={{ span: 16, offset: 3 }}
						xl={{ span: 16, offset: 3 }}
						md={{ span: 16, offset: 3 }}
						className='rounded card-custom'>
						<Title level={4} className='m-2 mt-5 mb-5 text-center'>
							Edit Location
						</Title>

						<Form

							form={form}
							style={{ marginBottom: "40px" }}
							eventKey='location-form'
							name='basic'
							initialValues={initialValues}
							labelCol={{
								xs: { span: 16, offset: 0 },
								sm: { span: 20, offset: 4 },
								md: { span: 20, offset: 4 },
								lg: { span: 20, offset: 4 },
								xl: { span: 20, offset: 4 },
							}}
							wrapperCol={{
								xs: { span: 16, offset: 0 },
								sm: { span: 20, offset: 4 },
								md: { span: 20, offset: 4 },
								lg: { span: 20, offset: 4 },
								xl: { span: 20, offset: 4 },
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete='off'
							labelAlign="left">
							<div>
								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Location Name '
									name='locationName'
									rules={[
										{
											required: true,
											message: "Please input your location name!",
										},
									]}>
									<Input className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Longitude'
									name='longitude'
									rules={[
										{
											required: true,
											message: "Please input your longitude!",
										},
									]}>
									<Input className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "20px" }}
									label='Latitude'
									name='latitude'
									rules={[
										{
											required: true,
											message: "Please input your latitude!",
										},
									]}>
									<Input className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									wrapperCol={{
										xs: { span: 20, offset: 0 },
										sm: { span: 20, offset: 4 },
										md: { span: 20, offset: 4 },
										lg: { span: 20, offset: 4 },
										xl: { span: 20, offset: 4 },
									}}
								>
									<Button
										type='submit'
										className="bg-[#E42976] text-white border-0"
										size='medium'
										htmlType='submit'
										block
										loading={loading}>
										Update Location
									</Button>
								</Form.Item>
							</div>
						</Form>
					</Col>
				</Row>

			</Modal>
		</>
	);
};
export default LocationEditPopup;
