import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Table,
  TimePicker,
  Typography,
  Modal,
} from "antd";
import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmploymentStatus,
  loadAllEmploymentStatus,
} from "../../redux/rtk/features/employemntStatus/employmentStatusSlice";

import { HexColorPicker } from "react-colorful";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Color Code",
      dataIndex: "colourValue",
      key: "colourValue",
      render: (colourValue) => (
        <div className="flex">
          <div
            className="rounded border border-gray-200"
            style={{
              marginRight: "10px",
              width: "20px",
              height: "20px",
              backgroundColor: colourValue,
            }}
          ></div>
          {colourValue}
        </div>
      ),
    },

    {
      id: 4,
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      id: 5,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/employment-status/${id}/`} />,
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <>
      <div className="flex items-center justify-between">
        <h5 className="text-color-2 text-[24px] font-[600]">
          Employment Status List
        </h5>
        <PageTitle title="Back" />
      </div>

      <div className="text-center my-2 px-2 flex justify-between items-center flex-wrap">
        {list && (
          <div className="flex items-center">
            <div>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                <CSVLink
                  data={list}
                  className="btn btn-dark btn-sm mb-1 text-white text-[15px] font-[400]"
                  filename="Emp_status"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          </div>
        )}
        <Button
          onClick={() => setModalOpen(true)}
          className="bg-[#07229E] text-[15px] font-[400] text-white border-0 h-[32px] py-0 px-[25px] flex items-center text-center rounded-[5px]"
        >
          Add Status
        </Button>
      </div>

      <Card className="border-0 shadow rounded-[15px]">
        <Table
          scroll={{ x: true }}
          loading={loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <AddStatusModal open={modalOpen} onClose={setModalOpen} />
    </>
  );
}

const AddEmploymentStatus = ({ drawer }) => {
  const { list, loading } = useSelector((state) => state.employmentStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllEmploymentStatus());
  }, []);

  return (
    <Fragment>
      <UserPrivateComponent permission={"readAll-employmentStatus"}>
        {drawer || <CustomTable list={list} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

function AddStatusModal({ open, onClose, drawer }) {
  const dispatch = useDispatch();
  const [addopen, setAddOpen] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loader, setLoader] = useState(false);

  const { Title } = Typography;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const FormData = {
      ...values,
      colourValue: color,
    };

    setLoader(true);
    const resp = await dispatch(addEmploymentStatus(FormData));
    if (resp.payload.message === "success") {
      setLoader(false);
      setAddOpen(onClose(!open));
      form.resetFields();
      dispatch(loadAllEmploymentStatus());
    } else {
      setLoader(false);
      setAddOpen(onClose(!open));
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
    setLoader(false);
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Employment Status
          </div>
        }
        className="global-modal-custom"
        open={open}
        onOk={() => setAddOpen(onClose(!open))}
        onCancel={() => setAddOpen(onClose(!open))}
        footer={null}
      >
        <UserPrivateComponent permission={"create-employmentStatus"}>
          <Row justify={drawer ? "center" : "space-between"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                form={form}
                style={{ marginBottom: "40px" }}
                eventKey="shift-form"
                name="basic"
                layout="vertical"
                labelCol={{
                  xs: { offset: 0, span: 16 },
                  sm: { offset: 0, span: 16 },
                  md: { offset: 4, span: 16 },
                  lg: { offset: 4, span: 16 },
                  xl: { offset: 4, span: 16 },
                }}
                wrapperCol={{
                  xs: { offset: 0, span: 16 },
                  sm: { offset: 0, span: 16 },
                  md: { offset: 4, span: 16 },
                  lg: { offset: 4, span: 16 },
                  xl: { offset: 4, span: 16 },
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your shift!",
                      },
                    ]}
                  >
                    <Input placeholder="Parmanet" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Color Code"
                    name="colourValue"
                  >
                    <Input
                      placeholder="#00FF00"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      onClick={() => setShowColorPicker(true)}
                    />
                    {showColorPicker && (
                      <div className="flex justify-between mt-3 mb-3">
                        <HexColorPicker
                          onChange={(i) => setColor(i)}
                          color={color}
                        />
                        <Button
                          type="danger"
                          onClick={() => setShowColorPicker(false)}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    label="Description"
                    name={"description"}
                  >
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { offset: 0, span: 16 },
                      sm: { offset: 0, span: 16 },
                      md: { offset: 4, span: 16 },
                      lg: { offset: 4, span: 16 },
                      xl: { offset: 4, span: 16 },
                    }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="primary"
                      size="large"
                      block
                      htmlType="submit"
                      loading={loader}
                    >
                      Add Employment Status
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

export default AddEmploymentStatus;
