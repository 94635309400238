import { Card, Col, Divider, List, Row } from "antd";
import Loader from "../../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadSingleProjectTasks } from "../../../redux/rtk/features/projectManagement/project/projectTask/projectTask";

import tw from "tailwind-styled-components";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../page-header/PageHeader";
import dayjs from "dayjs";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import styles from '../../leave/leave.module.css';
import { IoIosShareAlt } from 'react-icons/io';
import React, { useState } from 'react';
import { Modal, Table, Button } from 'antd';
import { GrDownload } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DeleteBtn from "../../Buttons/DeleteBtn";



function DataTableModal({ userData }) {
    const [modalVisible, setModalVisible] = useState(false);

    const columns = [
        {
            id: 2,
            title: "EMP ID",
            dataIndex: "employeeId",
            key: "employeeId",
        },
        {
            id: 3,
            title: "Name",
            key: "name",
            render: (record) => {
                return typeof record === "object"
                    ? record.firstName.toUpperCase() + " " + record.lastName.toUpperCase()
                    : record;
            },
        },
        {
            id: 4,
            title: "Department",
            dataIndex: "department",
            key: "department",
            render: (department) =>
                department?.name == null ? "-" : department?.name,
        },
    ];

    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <Link type="primary" onClick={showModal}>
                View Details
            </Link>
            <Modal
                width={800}
                visible={modalVisible}
                onCancel={closeModal}
                footer={false}
                className="bank-holiday-modal"
            >
                <div className={`${styles.title} text-center p-2 mb-2`}>
                    <h2> Task Assigned Staff</h2>
                </div>
                <Table
                    className={styles.TableStyle}
                    dataSource={userData}
                    columns={columns}
                    pagination={false}
                />
            </Modal>
        </div>
    );
}

const ViewTaskDetails = () => {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const taskData = useSelector((state) => state.projectTask.projectTasks);
    const users = taskData.user;


    useEffect(() => {
        if (id) {
            dispatch(loadSingleProjectTasks(id));
        }
    }, [id]);
    return (
      <div>
        <PageTitle title="Back" />

        <UserPrivateComponent permission={"readSingle-leaveApplication"}>
          <div className={styles.container}>
            <Card className={styles.cardStyle}>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className={`${styles.title} text-center mb-6 `}>
                    <h2>Task Details</h2>
                  </div>
                  <>
                    <List className="list-inside list-none border-none px-5  ">
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 ">
                        <ListItem>
                          Task ID :{" "}
                          <TextInside>
                            {taskData?.id == null ? "-" : taskData?.id}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Task Name :{" "}
                          <TextInside>
                            {taskData?.name == null ? "-" : taskData?.name}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Start Date:{" "}
                          <TextInside>
                            {taskData?.startDate == null
                              ? "-"
                              : dayjs(taskData?.startDate).format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          End Date:{" "}
                          <TextInside>
                            {taskData?.endDate == null
                              ? "-"
                              : dayjs(taskData?.endDate).format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Completion Time :{" "}
                          <TextInside>
                            {taskData?.completionTime == null
                              ? "-"
                              : taskData.completionTime}
                              <span>{" "}Hours</span>
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Priority :{" "}
                          <TextInside>
                            {taskData?.priority?.name == null ? (
                              "-"
                            ) : taskData?.priority?.name === "Medium" ? (
                              <span className="text-orange-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : taskData?.priority?.name == null ? (
                              "-"
                            ) : taskData?.priority?.name === "High" ? (
                              <span className="text-red-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : taskData?.priority?.name == null ? (
                              "-"
                            ) : taskData?.priority?.name === "Low" ? (
                              <span className="text-yellow-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-blue-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 flex-wrap">
                        <ListItem>
                          Task Status :{" "}
                          <TextInside>
                            {taskData?.taskStatus == null ? (
                              "-"
                            ) : taskData?.taskStatus === "PENDING" ? (
                              <span className="text-yellow-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : taskData?.taskStatus == null ? (
                              "-"
                            ) : taskData?.taskStatus === "COMPLETED" ? (
                              <span className="text-green-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : taskData?.taskStatus == null ? (
                              "-"
                            ) : taskData?.taskStatus === "INPROGRESS" ? (
                              <span className="text-orange-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-red-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Attachments :{" "}
                          <TextInside>
                            {taskData?.adminattachment == null ||
                            taskData?.adminattachment == "null" ? (
                              "No Attachment"
                            ) : taskData?.adminattachment ? (
                              <a
                                href={taskData?.adminattachment}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500 flex items-center"
                              >
                                Download&nbsp;
                                <FaDownload className={styles.color} />
                              </a>
                            ) : (
                              "No Attachment"
                            )}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className=" bg-[#F3F4F6] text-left p-3">
                        <strong style={{ color: "#47515E" }}>
                          Description:
                        </strong>
                        <p>
                          {taskData?.description == null
                            ? "-"
                            : taskData?.description
                            ? taskData?.description
                            : "No Description"}
                        </p>
                      </div>
                    </List>
                  </>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="w-[80%] mx-auto">
            <Divider />
          </div>
          <div className={styles.container}>
            <Card className={styles.cardStyle}>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className={`${styles.title} text-center `}>
                    <h2>User Task Details</h2>
                  </div>
                  <strong className="flex justify-center m-2">
                    <p style={{ color: "red" }}>
                      This task is already assigned to{" "}
                      {taskData?.numAssignedUsers == null
                        ? "0"
                        : taskData?.numAssignedUsers}{" "}
                      number of staff .
                    </p>
                    <DataTableModal userData={users} />
                    <IoIosShareAlt
                      className="ml-2"
                      style={{ fontSize: "20px" }}
                    />
                  </strong>
                  <>
                    <List className="list-inside list-none border-none px-5  ">
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 ">
                        <ListItem>
                          Assigned By:{" "}
                          <TextInside>
                            {(taskData.assignedBy?.firstName || "ON") +
                              " " +
                              (taskData.assignedBy?.lastName || "REVIEW")}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Task Name :{" "}
                          <TextInside>
                            {taskData?.name == null ? "-" : taskData?.name}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Start Date:{" "}
                          <TextInside>
                            {taskData?.startDate == null
                              ? "-"
                              : dayjs(taskData?.startDate).format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          End Date:{" "}
                          <TextInside>
                            {taskData?.endDate == null
                              ? "-"
                              : dayjs(taskData?.endDate)
                                  .utc()
                                  .format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Completion Time :{" "}
                          <TextInside>
                            {taskData?.completionTime == null
                              ? "-"
                              : taskData.completionTime}
                              <span>{" "}Hours</span>
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Priority :{" "}
                          <TextInside>
                            {/* {taskData.priority.name} */}
                            {taskData?.priority?.name === "Medium" ? (
                              <span className="text-orange-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : taskData?.priority?.name === "High" ? (
                              <span className="text-red-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : taskData?.priority?.name === "Low" ? (
                              <span className="text-yellow-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-red-500">
                                {taskData?.priority?.name?.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 flex-wrap">
                        <ListItem>
                          Task Status :{" "}
                          <TextInside>
                            {taskData?.taskStatus === "PENDING" ? (
                              <span className="text-yellow-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : taskData?.taskStatus === "COMPLETED" ? (
                              <span className="text-green-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : taskData?.taskStatus === "INPROGRESS" ? (
                              <span className="text-orange-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-red-500">
                                {taskData?.taskStatus?.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Attachments :{" "}
                          <TextInside>
                            {taskData?.userAttachment == null ||
                            taskData?.userAttachment == "null" ? (
                              "No Attachment"
                            ) : taskData?.userAttachment ? (
                              <a
                                href={taskData?.userAttachment}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500 flex items-center"
                              >
                                {" "}
                                Download&nbsp;
                                <FaDownload className={styles.color} />
                              </a>
                            ) : (
                              "No Attachment"
                            )}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className=" bg-[#F3F4F6] text-left p-3">
                        <strong style={{ color: "#47515E" }}>
                          Review Comment:
                        </strong>
                        <p>
                          {taskData?.reviewComment
                            ? taskData?.reviewComment
                            : "No Comments"}
                        </p>
                      </div>
                    </List>
                  </>
                </Col>
              </Row>
            </Card>
          </div>
        </UserPrivateComponent>
      </div>
    );
};

const ListItem = tw.li`
  text-sm
  text-gray-600
  font-semibold
  py-2
  px-2
  bg-gray-100
  mb-1.5
  rounded
  w-96
  flex
  justify-start
  text-left

`;

const TextInside = tw.p`
  ml-2
  text-sm
  text-gray-900
`;
export default ViewTaskDetails;

