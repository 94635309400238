import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Button, Radio, Select, TimePicker } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styles from "./leave.module.css";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addTraining } from "../../redux/rtk/features/training/trainingSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageTitle from "../page-header/PageHeader";

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {};
const TrainingSchedule = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const onFinish = async (values) => {
    setLoader(true);
    const data = {
      ...values,
      leaveFrom: dayjs(state[0]?.startDate).format("YYYY-MM-DD"),
      leaveTo: dayjs(state[0]?.endDate).format("YYYY-MM-DD"),
    };
    const resp = await dispatch(addTraining(data));
    if (resp.payload.message === "success") {
      setLoader(false);
      form.resetFields();
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding Training Day");
    setLoader(false);
  };

  return (
    <>
      <PageTitle title="Back" />
      <div className={styles.container}>
        <Card className={`rounded-[10px] ${styles.card}`}>
          <div className={`${styles.title} text-center `}>
            <h2 className="">Schedule Training Day</h2>
          </div>
          <Row gutter={[16, 16]}>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="mt-2 pb-4 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden sm:overflow-hidden xsm:overflow-x-auto xxs:overflow-x-auto xxxs:overflow-x-auto"
            >
              <DateRange
                className={styles.cardBody}
                ShowInputs={false}
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                rangeColors={["#0622A1", "#0622A1", "#0622A1"]}
              />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24} className="mt-2 p-2">
              <Form
                className={` p-3`}
                layout="vertical"
                size="large"
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                labelAlign="left"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Training Day Name:"
                  name="day"
                  rules={[
                    {
                      required: true,
                      message: "Training Day Name Required!",
                    },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder="Enter Training Day Name"
                    style={{
                      border: "none",
                      boxShadow: "0px 0px 5px  #d1cccc",
                    }}
                  />
                </Form.Item>

                <div className={`${styles.Button} text-center mt-20`}>
                  <Button key="submit" type="submit" className="bg-[#E42976] text-white border-0" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default TrainingSchedule;
