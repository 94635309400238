import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  DatePicker,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  addShift,
  loadAllShift,
} from "../../redux/rtk/features/shift/shiftSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import moment from "moment";
import { getISOWeek } from "date-fns";
import {
  loadAllLocation,
  loadSingleLocation,
} from "../../redux/rtk/features/location/locationSlice";
import { loadRoomsByLocationId } from "../../redux/rtk/features/rooms/roomSlice";
import {
  CreateShiftManagement,
  loadSingleShifts,
} from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import { IoMdEye } from "react-icons/io";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";
import styles from "../leave/leave.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";

function CustomTable({ list, drawer }) {
  const [row, setRow] = useState([list?.user]);
  const { RangePicker } = DatePicker;
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [loader, setLoader] = useState(false);
  const [shift, setShift] = useState(false);
  const [shiftUser, setShiftUser] = useState();
  const [sChange, setSChange] = useState(false);


  useEffect(() => {
    if (list) {
      setRow([list?.user]);
    }
  }, [list]);

  const dateFormat = "YYYY/MM/DD";
  const columns = [
    {
      id: 1,
      title: "Emp id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      id: 2,
      title: "Employee Name",
      dataIndex: ["firstName", "lastName"],
      key: "user",
      render: (text, record) =>
        record?.firstName == null
          ? "--"
          : record?.firstName + " " + record.lastName,
    },

    {
      id: 3,
      title: "Designation",
      dataIndex: "user",
      key: "user",
      render:(text, record)=>record?.designationHistory[0] ? record?.designationHistory[0]?.designation?.name : "--"
    },
    {
      id: 5,
      title: "View Shift Details",
      dataIndex: ["firstName", "lastName", "id"],
      key: "id",
      render: (text, record) => (
        <div>
          <button
            onClick={(e) => handleShiftChange(record)}
            type="primary"
            className="text-[16px] font-[600] text-[#23B9F8]"
          >
            <IoMdEye className="text-[#23B9F8] text-[20px]" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => Object.keys(arr).map((i) => ({ ...i, key: i.id }));

  function handleShiftChange(values) {
    setShiftUser(values);
    setShift(true);
  }
  const filter_list = list?.schedule?.filter((value, index) =>
    value.status ? value : ""
  );

  console.log(filter_list,'filter_list')
  return (
    <>
    <div className="flex items-center mb-6 justify-between">
      <h5 className="department-list-title text-color-2 text-[24px] font-[600]">
        View Shift
      </h5>
      <PageTitle title="Back" />
    </div>
      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Week Date Range</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0">
          <div className="ant-card-body">
            <RangePicker
              format={dateFormat}
              value={[moment(list.shiftFrom), moment(list.shiftTo)]}
              className="mb-3"
              disabled
            />
            <h5 className="text-[16px] font-[600] text-gray-400">Week</h5>
            <Input
              className="bg-gray-200"
              value={"WEEK" + "  " + list?.weekNumber}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="mx-2 mb-4">
        <h5 className="text-[16px] font-[600] mb-1">Location :</h5>
        <div className="card ant-shadow rounded-[10px] p-0 border-0">
          <div className="ant-card-body">
            <Form.Item className="!mb-0">
              <Input
                className="bg-gray-200"
                value={list?.location?.locationName}
                disabled
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <Card bordered={false} className="ant-shadow rounded-[10px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={!list}
          columns={columnsToShow}
          dataSource={row}
        />
      </Card>

      <Modal
        centered
        open={shift}
        onOk={() => setShift(false)}
        onCancel={() => setShift(false)}
        footer={null}
        width={1000}
        className="shift-modal-custom mt-[30px] mb-[40px]"
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
              borderBottom: "unset !important",
            }}
          >
            View Shift Details
          </div>
        }
      >
        <UserPrivateComponent permission={"create-shift"}>
          <div className="text-center mb-4">
            <h2 className="text-[18px] font-[600]">
              Shift For Staff:&nbsp;
              <span className="font-[500]">
                {shiftUser?.firstName + " " + shiftUser?.lastName}
              </span>
            </h2>
          </div>
          <div className={sChange == true ? "hidden" : "block"}>
            <div className="w-full overflow-auto">
              <table className="userShift-table">
                <thead>
                  <tr>
                    <th>Days</th>
                    <th style={{ width: "15%" }}>Date</th>
                    <th>Room</th>
                    <th>Start time</th>
                    <th>End time</th>
                    <th style={{ width: "15%" }}>Break time&nbsp;(min)</th>
                    <th style={{ width: "15%" }}>Folder time&nbsp;(min)</th>
                  </tr>
                </thead>

                <tbody>
                  {filter_list?.map((data, ind) => {
                    return (
                      <tr>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <Checkbox checked={data?.status}>
                              {data.day}
                            </Checkbox>
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <DatePicker
                              disabled
                              value={moment(
                                dayjs(data?.shiftDate).format("DD-MM-YYYY"),
                                "DD-MM-YYYY"
                              )}
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <Input value={data?.room ==  null ? 'no rooms found' : data?.room?.roomName} disabled />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <TimePicker
                              disabled
                              value={moment(
                                dayjs(data?.startTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )}
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <TimePicker
                              disabled
                              value={moment(
                                dayjs(data?.endTime).format("HH:mm:ss"),
                                "HH:mm:ss"
                              )}
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <InputNumber
                              value={data?.breakTime}
                              disabled
                              className="w-full h-[34px] !py-[1px]"
                              placeholder="00:00 min"
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "10px" }}>
                            <InputNumber
                              value={data?.folderTime}
                              disabled
                              className="w-full h-[34px] !py-[1px]"
                              placeholder="00:00 min"
                            />
                          </Form.Item>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setSChange(true)}
                className="py-2 flex items-center text-[16px] font-[500] hover:text-[#0723a1]"
              >
                Next&nbsp;
                <BsArrowRight />
              </button>
            </div>
          </div>
          <div className={sChange == false ? "hidden" : "block"}>
            <p>General information</p>
            <TextArea
              disabled
              value={list?.generalInfo}
              placeholder="General information...."
              showCount
              rows={7}
              className={`${styles.CommentBox}`}
            />
            <div className="flex justify-end items-center gap-4 mt-6">
              <button
                onClick={() => setSChange(false)}
                className="p-4 flex items-center text-[16px] font-[500] hover:text-[#0723a1]"
              >
                <BsArrowLeft />
                &nbsp; Previous
              </button>
              <button
                onClick={(e) => setShift(false)}
                className="py-2 px-4 text-[18px] font-[500] text-white rounded-[15px] bg-[#E42976] border-0"
              >
                Close
              </button>
            </div>
          </div>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const ViewShift = ({ drawer }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const single_shift = useSelector(
    (state) => state.ShiftManagement.SingleShift
  );

  useEffect(() => {
    dispatch(loadSingleShifts(id));
  }, []);

  console.log(single_shift, "single_shift");
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={single_shift} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default ViewShift;
