import { Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";

const UpdateBtn = ({ path }) => {
	return (
		<div>
			<Tooltip title='Update'>
				<Link to={path}>
					<button className='px-2'>
					<AiOutlineEdit size={25} color="#23B9F8" />
					</button>
				</Link>
			</Tooltip>
		</div>
	);
};

export default UpdateBtn;
