import {
	Button,
	Card,
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Table,
	Typography,
	Modal
} from "antd";

import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
	addSingleWeeklyHoliday,
	loadAllWeeklyHoliday,
} from "../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
function CustomTable({ list, loading, drawer }) {
	const [columnsToShow, setColumnsToShow] = useState([]);
	const [addWeeklyHoliday, setAddWeeklyHoliday] = useState(false)
	const [loader, setLoader] = useState(false);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { Title } = Typography;

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			id: 2,
			title: "Name",
			dataIndex: "name",
			key: "name",
		},

		{
			id: 3,
			title: "Start Day",
			dataIndex: "startDay",
			key: "startDay",
		},

		{
			id: 3,
			title: "End Day",
			dataIndex: "endDay",
			key: "endDay",
		},
		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => <ViewBtn path={`/admin/holiday/week/${id}/`} />,
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));
	const onFinish = async (values) => {
		setLoader(true);
		const resp = await dispatch(addSingleWeeklyHoliday(values));

		if (resp.payload.message === "success") {
			setLoader(false);
			form.resetFields();
			dispatch(loadAllWeeklyHoliday());
			setAddWeeklyHoliday(false)

		} else {
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
		setLoader(false);
	};


	const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
          id: data.id,
          Name: data.name == null ? "-" : data.name,
          Start_Day: data.startDay == null ? "-" : data.startDay,
          End_Day: data.endDay == null ? "-" : data.endDay,
        }))
      : "";
  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        Weekly Holiday List
      </h5>
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <div className="text-end">
          <Button
            className={list ? "px-5" : "px-5 mb-4"}
            type="primary"
            onClick={() => setAddWeeklyHoliday(true)}
          >
            Add Weekly Holiday
          </Button>
        </div>

        {list && (
          <div className="flex justify-between">
            <div className="mt-3">
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={CSVlist}
                  className="btn btn-dark btn-sm mb-1"
                  filename="weekly-holiday"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          </div>
        )}

        <Table
          scroll={{ x: true }}
          loading={!list || loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add Weekly Holiday
          </div>
        }
        centered
        open={addWeeklyHoliday}
        onOk={() => setAddWeeklyHoliday(false)}
        onCancel={() => setAddWeeklyHoliday(false)}
        footer={null}
        className="global-modal-custom"
      >
        <UserPrivateComponent permission={"create-weeklyHoliday"}>
          <Row justify={drawer ? "center" : "space-between"}>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              lg={{ span: 17, offset: 4 }}
              xl={{ span: 17, offset: 4 }}
              md={{ span: 20, offset: 2 }}
            >
              <Form
                style={{ marginBottom: "40px" }}
                form={form}
                layout="vertical"
                eventKey="department-form"
                name="basic"
                // labelCol={{
                // 	offset:6,
                // 	span:12
                // }}
                // wrapperCol={{
                // 	offset:6,
                // 	span: 12
                // }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input name!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="Saturday-Friday"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Start Day"
                    name="startDay"
                    rules={[
                      {
                        required: true,
                        message: "Please input start day!",
                      },
                    ]}
                  >
                    <Select
                      className="border-none ant-shadow"
                      placeholder="Select Start Day"
                    >
                      <Select.Option value="Saturday">Saturday</Select.Option>
                      <Select.Option value="Sunday">Sunday</Select.Option>
                      <Select.Option value="Monday">Monday</Select.Option>
                      <Select.Option value="Tuesday">Tuesday</Select.Option>
                      <Select.Option value="Wednesday">Wednesday</Select.Option>
                      <Select.Option value="Thursday">Thursday</Select.Option>
                      <Select.Option value="Friday">Friday</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    label="End Day"
                    name="endDay"
                    rules={[
                      {
                        required: true,
                        message: "Please input End day!",
                      },
                    ]}
                  >
                    <Select
                      className="border-none ant-shadow"
                      placeholder="Select Start Day"
                    >
                      <Select.Option value="Saturday">Saturday</Select.Option>
                      <Select.Option value="Sunday">Sunday</Select.Option>
                      <Select.Option value="Monday">Monday</Select.Option>
                      <Select.Option value="Tuesday">Tuesday</Select.Option>
                      <Select.Option value="Wednesday">Wednesday</Select.Option>
                      <Select.Option value="Thursday">Thursday</Select.Option>
                      <Select.Option value="Friday">Friday</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="mt-3"
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { span: 20, offset: 0 },
                      sm: { span: 20, offset: 2 },
                      md: { span: 20, offset: 2 },
                      lg: { span: 22, offset: 2 },
                      xl: { span: 24, offset: 0 },
                    }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="primary"
                      size="medium"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Add Weekly Holiday
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const AddWeeklyHoliday = ({ drawer }) => {
	const { list, loading } = useSelector((state) => state.weeklyHoliday);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllWeeklyHoliday());
	}, []);


	return (
		<Fragment bordered={false}>

			<UserPrivateComponent permission={"readAll-weeklyHoliday"}>
				{drawer || <CustomTable list={list} loading={loading} />}
			</UserPrivateComponent>
		</Fragment>
	);
};

export default AddWeeklyHoliday;
