import { Button, Col, DatePicker, Form, Input, Modal, Row, Typography } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BtnEditSvg from "../Button/btnEditSvg";
import { useDispatch } from "react-redux";
import {
	loadSinglePublicHoliday,
	updatePublicHoliday,
} from "../../../redux/rtk/features/publicHoliday/publicHolidaySlice";
import dayjs from "dayjs";
import moment from "moment";
import { AiOutlineEdit } from "react-icons/ai";

const PublicHolidayEdit = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams("id");
	const { Title } = Typography;

	const [loader, setLoader] = useState(false);
	const [date, setDate] = useState(dayjs(data?.date).format());

	const dispatch = useDispatch();

	const onFinish = async (values) => {
		setLoader(true);
		const resp = await dispatch(updatePublicHoliday({ id, values }));
		if (resp.payload.message === "success") {
			setLoader(false);
			setIsModalOpen(false);
			dispatch(loadSinglePublicHoliday(id));
		} else {
			setLoader(false);
		}
	};

	const initialValues = {
		name: data?.name,
		date: moment(data?.date),
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
		setLoader(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	return (
		<>
			<button onClick={showModal}>
				<AiOutlineEdit size={30} color="#23B9F8" />
			</button>
			<Modal
				// title='Bank Holiday Edit'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				className="BankHolidayEditModal"
			>
				<Row gutter={16}>
					<Col
						xs={{ span: 20, offset: 1 }}
						sm={{ span: 20, offset: 1 }}
						lg={{ span: 16, offset: 4 }}
						xl={{ span: 16, offset: 4 }}
						md={{ span: 16, offset: 4 }}
					>	<Title level={4} className='m-2 mt-5 mb-5 text-center'>
							Bank Holiday Edit
						</Title>
						<Form
							style={{ marginBottom: "50px" }}
							eventKey='department-form'
							initialValues={{ ...initialValues }}
							layout="vertical"
							name='basic'
							// labelCol={{
							// 	offset: 7,
							// 	span: 7,
							// }}
							// wrapperCol={{
							// 	offset: 7,
							// 	span: 12,
							// }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete='off'>
							<div>
								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Name'
									name='name'
									rules={[
										{
											required: true,
											message: "Please input name!",
										},
									]}>
									<Input className="border-none ant-shadow" />


								</Form.Item>

								<Form.Item
									style={{ marginBottom: "15px" }}
									label='Date'
									name='date'
									rules={[{ required: true, message: "Please select date!" }]}>
									<DatePicker className="border-none ant-shadow" format={"YYYY-DD-MM"} />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									wrapperCol={{
										xs: { span: 24, offset: 0 },
										sm: { span: 24, offset: 0 },
										md: { span: 16, offset: 4 },
										lg: { span: 16, offset: 4 },
										xl: { span: 16, offset: 4 },
									}}
								>
									<Button
										className="mt-3 bg-[#E42976] text-white border-0"
										onClick={() => setLoader(true)}
										type='submit'
										size='medium'
										htmlType='submit'
										block
										loading={loader}>
										Update Public Holiday
									</Button>
								</Form.Item>
							</div>
						</Form>

					</Col>
				</Row>

			</Modal>
		</>
	);
};
export default PublicHolidayEdit;
